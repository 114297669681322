import { resolveRealtimePath } from './helper';

const auth = resolveRealtimePath('auth');
const body = resolveRealtimePath('body');
const mail = resolveRealtimePath('mail');
const neck = resolveRealtimePath('neck');
const operators = resolveRealtimePath('operators');
const recordNumbers = resolveRealtimePath('recordNumbers');
const router = `${resolveRealtimePath('router')}/steps`;
const settings = resolveRealtimePath('settings');
const util = resolveRealtimePath('util');

export default {
  auth,
  body,
  mail,
  neck,
  operators,
  recordNumbers,
  router,
  settings,
  util,
};
