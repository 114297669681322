import { isDevSite, useTestData } from 'shared/util/site';

export const resolveFirestorePath = (path: string, env: 'dev' | 'test' | 'prod' | 'backup' | null = null) => {
  if (env) return `_environments/${env}/${path}`;

  if (isDevSite()) return `_environments/dev/${path}`;
  if (useTestData) return `_environments/test/${path}`;
  return `_environments/prod/${path}`;
};

export const resolveRealtimePath = (path: string, env: 'prod' | 'dev' | 'test' | 'backup' | null = null) => {
  if (env) return `${path}${env[0].toUpperCase()}${env.slice(1)}`;

  if (isDevSite()) return `${path}Dev`;
  if (useTestData) return `${path}Test`;
  return path;
};

export const rewriteFirestorePath = (resolvedPath: string, newEnv: 'dev' | 'test' | 'prod' | 'backup') => {
  const match = resolvedPath.match(/^_environments\/(?:dev|test|prod|backup)\/(.+)$/);
  if (!match) throw new Error('Invalid Firestore path format');
  
  return `_environments/${newEnv}/${match[1]}`;
};

export const rewriteRealtimePath = (resolvedPath: string, newEnv: 'dev' | 'test' | 'prod' | 'backup') => {
  const match = resolvedPath.match(/^(.+?)(?:Dev|Test|Prod|Backup)?$/);
  if (!match) throw new Error('Invalid Realtime Database path format');
  
  const basePath = match[1];
  const envSuffix = newEnv === 'prod' ? '' : newEnv[0].toUpperCase() + newEnv.slice(1);
  return newEnv === 'prod' ? basePath : `${basePath}${envSuffix}`;
};
