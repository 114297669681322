import { openDB, DBSchema, IDBPDatabase } from 'idb';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import db from './index';
import { devLog } from 'shared/util/logging';

interface CacheEntry {
  parts: Array<{
    Sku: string;
    pricing: any;
    [key: string]: any;
  }>;
  lastUpdated: number;
  version: number;
}

interface CustomerPricingDB extends DBSchema {
  'customer-pricing': {
    key: string;
    value: CacheEntry;
  };
  'cache-metadata': {
    key: string;
    value: {
      lastInvalidation: number;
      version: number;
    };
  };
}

// Cache duration: 24 hours for large catalogs
const CACHE_DURATION = 1000 * 60 * 60 * 24;

// Track active subscriptions to prevent memory leaks
const activeSubscriptions = new Map<string, () => void>();

export const setupLocalCache = async () => {
  return openDB<CustomerPricingDB>('wildwood-cache', 1, {
    upgrade(db) {
      db.createObjectStore('customer-pricing');
      db.createObjectStore('cache-metadata');
    },
  });
};

export const getCachedPricing = async (customerId: string) => {
  try {
    const startTime = performance.now();
    const db = await setupLocalCache();
    const cached = await db.get('customer-pricing', customerId);
    
    if (cached && Date.now() - cached.lastUpdated < CACHE_DURATION) {
      const retrievalTime = performance.now() - startTime;
      devLog('localCache', 45, `Cache hit for customer ${customerId}, retrieved in ${retrievalTime.toFixed(2)}ms`);
      return cached.parts;
    }
    
    devLog('localCache', 49, `Cache miss for customer ${customerId}`);
    return null;
  } catch (error) {
    console.error('Error accessing cache:', error);
    return null;
  }
};

export const setCachedPricing = async (customerId: string, parts: any[]) => {
  try {
    const startTime = performance.now();
    const db = await setupLocalCache();
    
    // Ensure each part has its pricing data preserved
    const processedParts = parts.map(part => ({
      ...part,
      pricing: part.pricing || null  // Explicitly preserve pricing
    }));

    await db.put('customer-pricing', {
      parts: processedParts,
      lastUpdated: Date.now(),
      version: 1,
    }, customerId);
    
    const writeTime = performance.now() - startTime;
    devLog('localCache', 66, `Cache updated for customer ${customerId} in ${writeTime.toFixed(2)}ms, stored ${processedParts.length} parts`);
  } catch (error) {
    console.error('Error updating cache:', error);
  }
};

export const setupRealtimeSync = (
  customerId: string, 
  onUpdate: (parts: any[]) => void
) => {
  // Cleanup any existing subscription
  if (activeSubscriptions.has(customerId)) {
    activeSubscriptions.get(customerId)?.();
    activeSubscriptions.delete(customerId);
  }

  const q = query(
    collection(db.firestore, db.part.records),
    where('customer', '==', customerId)
  );

  // Set up real-time listener
  const unsubscribe = onSnapshot(q, async (snapshot) => {
    const changes = snapshot.docChanges();
    
    if (changes.length > 0) {
      devLog('localCache', 91, `Received ${changes.length} changes for customer ${customerId}`);
      
      const cached = await getCachedPricing(customerId);
      if (cached) {
        let hasActualChanges = false;
        const updatedParts = [...cached];
        
        changes.forEach((change) => {
          const doc = change.doc;
          const data = doc.data();
          
          if (change.type === 'added' || change.type === 'modified') {
            const index = updatedParts.findIndex(p => p.Sku === data.Sku);
            if (index >= 0) {
              // Only update if data actually changed
              if (JSON.stringify(updatedParts[index]) !== JSON.stringify(data)) {
                updatedParts[index] = data;
                hasActualChanges = true;
              }
            } else {
              updatedParts.push(data);
              hasActualChanges = true;
            }
          } else if (change.type === 'removed') {
            const index = updatedParts.findIndex(p => p.Sku === data.Sku);
            if (index >= 0) {
              updatedParts.splice(index, 1);
              hasActualChanges = true;
            }
          }
        });

        // Only update cache and notify if there were actual changes
        if (hasActualChanges) {
          devLog('localCache', 92, `Applying ${changes.length} actual changes for customer ${customerId}`);
          await setCachedPricing(customerId, updatedParts);
          onUpdate(updatedParts);
        } else {
          devLog('localCache', 93, 'No actual data changes detected, skipping update');
        }
      }
    }
  });

  activeSubscriptions.set(customerId, unsubscribe);
  return unsubscribe;
};

export const clearCache = async (customerId?: string) => {
  try {
    const db = await setupLocalCache();
    if (customerId) {
      await db.delete('customer-pricing', customerId);
      devLog('localCache', 134, `Cache cleared for customer ${customerId}`);
    } else {
      await db.clear('customer-pricing');
      devLog('localCache', 137, 'Cache cleared for all customers');
    }
  } catch (error) {
    console.error('Error clearing cache:', error);
  }
};

export const updateCachedPart = async (customerId: string, updatedPart: any) => {
  try {
    const db = await setupLocalCache();
    const cached = await db.get('customer-pricing', customerId);
    
    if (cached) {
      const updatedParts = [...cached.parts];
      const index = updatedParts.findIndex(p => p.Sku === updatedPart.Sku);
      
      // Preserve existing pricing if not provided in update
      const partToUpdate = {
        ...updatedPart,
        pricing: updatedPart.pricing || (index >= 0 ? updatedParts[index].pricing : null)
      };
      
      if (index >= 0) {
        updatedParts[index] = partToUpdate;
      } else {
        updatedParts.push(partToUpdate);
      }
      
      await db.put('customer-pricing', {
        parts: updatedParts,
        lastUpdated: Date.now(),
        version: cached.version,
      }, customerId);
      
      devLog('localCache', 150, `Cache updated for part ${updatedPart.Sku}`);
      return updatedParts;
    }
    return null;
  } catch (error) {
    console.error('Error updating part in cache:', error);
    return null;
  }
}; 