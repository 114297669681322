import { resolveFirestorePath } from './helper';

const glCodes = resolveFirestorePath('gl-codes');
const productCodes = resolveFirestorePath('product-codes');
const creditMemos = resolveFirestorePath('credit-memos');

export default {
  glCodes,
  productCodes,
  creditMemos,
};
