// eslint-disable-next-line import/prefer-default-export
import { Timestamp, arrayUnion } from 'firebase/firestore';

import { findIndex, includes } from 'lodash';
import { IRunner, IShopOrder } from '../../pages/Orders/types';
import { IRouterStep } from '../../pages/ProductionSchedule/types';
import { IBomItem, IOrderItem } from '../types/dbRecords';

import _ from 'lodash'

export const CONSTRUCTION_JASON = {
  stationId: 'McjyywD915d26qI63BQHoLAgoY82',
  stepId: '8itJFuQJVT0',
};
export const RELEASE_STATION = {
  stationId: 'thoqTZixlrhlYxWy52GHUvNUUr42',
  stepId: ['bIKQ9ANcC', 'ARft0FS3O'],
};

export const CNC_STATION_NECK = {
  stationId: '7l8NTrVLWFYReCmFYnbabqNXxTe2',
  stepdId: ['DXLLG0NQx', 'rAkvCIHRH6i'],
};

export const scanStation = (user: any) => {
  if (user.uid === RELEASE_STATION.stationId) return 'Release Station';
  if (user.uid === 'uuikiqzedJPiJ0t1zc9bJn2cDQt2') return 'Material Assembly';
  if (user.uid === 'n973nmDar7Xs7j74bmCJ8IcGlXp2') return 'Construction';
  if (user.uid === CONSTRUCTION_JASON.stationId) return 'Construction';
  if (user.uid === 'kF8mOZ8sIXXAOVPjFyJty8aFCfl1' || user.uid === 'gEmUhPcMPHeCYUCA8o0dEXdBoSo1') return 'Finishing';
  if (user.uid === 'fUl6oWOQOYSHxDkI9BVYzgi8suj1') return 'Dry Room';
  if (user.uid === CNC_STATION_NECK.stationId) return 'CNC';
  if (user.uid === 'mAmnJq0iJAUpSCZgKC5MIOEHrTD2') return 'QA / Shipping';
};

export const selectAudio = (currentUserId: string) => (
  currentUserId === RELEASE_STATION.stationId ? '/audio/beep.mp3' : '/audio/scan_success.mp3');

export const runnerHistory = (runner: IRunner, stationId: string) => {
  const history = runner.history || [];
  if (stationId === history[history.length - 1]?.step) return history;
  return [
    ...history,
    {
      dateEntered: Timestamp.fromDate(new Date()),
      step: stationId,
    },
  ];
};

export const updateAllRunners = (order: IShopOrder, stepId: string): [IRunner, IShopOrder] => {
  const runners = order.runners?.map((r: IRunner) => ({
    ...r,
    history: runnerHistory(r, stepId),
    step: stepId,
  }));

  const SO = {
    ...order,
    runners,
  };

  return [runners[0], SO];
};

export const updateRunnerBom = (r: IRunner, stepId: string, routerSteps: IRouterStep[], partType: 'neck'|'body'): any => {
  const consumedParts = {};
  const orderTypeSteps = routerSteps.filter((step: IRouterStep) => step.type === partType);
  const endStep = findIndex(orderTypeSteps, (step: IRouterStep) => step.id === stepId);
  const consumptionSteps = orderTypeSteps.slice(0, endStep + 1).map((step: IRouterStep) => step.id);
  const updatedRunnerParts = r.parts.map((p: IOrderItem) => {
    const bom = p.bom.map((b: IBomItem) => {
      // if the step the bom item is to be consumed at is in the consumption steps array,
      // consume the quantity of the part in the bom minus whatever has already been consumed.
      // this will effectively prevent overconsumption and make the quantity assigned the maximum consumable quantity
      const quantityNeeded = b.quantity * p.quantityAssigned;
      const needToConsume = quantityNeeded !== (b.quantityConsumed || 0);
      let quantityConsumed = 0;
      if (includes(consumptionSteps, b.stepId)) {
        if (needToConsume) quantityConsumed = quantityNeeded - (b.quantityConsumed || 0);
        else quantityConsumed = b.quantityConsumed;
      }
      if (quantityConsumed > 0 && needToConsume) {
        if (includes(Object.keys(consumedParts), b.Sku)) {
          // @ts-ignore
          consumedParts[b.Sku] += quantityConsumed;
        } else {
          // @ts-ignore
          consumedParts[b.Sku] = quantityConsumed;
        }
        // setConsumedParts(newConsumed);
      }
      return {
        ...b,
        quantityConsumed: quantityConsumed ?? 0,
      };
    });
    return { ...p, bom };
  });
  return { updatedRunner: { ...r, parts: updatedRunnerParts }, consumedParts };
};

export const updateRunners = (order: IShopOrder, runner: IRunner, stepId: string): [IRunner, IShopOrder] => {
  // if this is the release scanner, update all runners at once, and return the first runner on the order.
  if (_.includes(['cIKQ9ANcC', 'fArt0FS3O'], stepId)) return updateAllRunners(order, stepId);

  const runnerIndex = _.findIndex(order.runners, (r: IRunner) => r.id === runner.id);
  const updatedRunner = { ...runner, history: runnerHistory(runner, stepId), step: stepId };

  const runners = [
    ...order.runners.slice(0, runnerIndex),
    updatedRunner,
    ...order.runners.slice(runnerIndex + 1),
  ];

  const SO = {
    ...order,
    runners,
  };

  return [updatedRunner, SO];
};
