import shortid from 'shortid';
import {
  every, find, first, flattenDeep, includes, sortBy,
} from 'lodash';
import {
  IConfigEntry, IConfigTerm, IConfigTermType, ICustomerPart,
} from 'shared/types/parts';
import { addMeta, cleanMeta, isMeta } from 'shared/text';
import db from 'shared/db/index';
import { firestore } from 'vendor/Firebase';
import {
  BODY_WOOD_MAP, BODY_WOOD_WEIGHTS, NECK_WOOD_MAP, INLAY_MAP,
} from './parsingTerms';
import { termTypes, terms as __terms } from './terms';
import { resolveInlay, resolveWood, resolvePickups } from './partResolver';
import { collection, getDocs } from 'firebase/firestore';

export const customerConfig = (globalConfig: any, customerId: string, partClass: 'GB'|'BB'|'BN'|'GN', forExport: boolean = false) => {
  const custConfig = globalConfig[customerId];
  const partType = includes(['BN', 'GN'], partClass) ? 'neck' : 'body';
  if (!custConfig) {
    return {
      ...globalConfig.UNIVERSAL[partType],
      ...globalConfig.UNIVERSAL.global,
    };
  }

  const partConfig = {};

  Object.entries(custConfig[partType]).forEach((e: [string, any]) => {
    const [k, v] = e;
    if (forExport && !v.export) return;
    const universal = find(globalConfig.UNIVERSAL[partType], (pt: any) => pt.objectKey === v.objectKey);
    partConfig[k] = {
      ...v,
      label: universal?.label || '',
    };
  });

  Object.entries(globalConfig.UNIVERSAL.global).forEach((e: [string, any]) => {
    const [k, v] = e;
    if (forExport && !v.export) return;
    partConfig[k] = v;
  });

  return {
    ...partConfig,
  };
};

export const UNIVERSAL_REPLACEMENT_TERMS = {
  Black: ['Blk', 'Black'],
  White: ['Wht'],
  Gold: ['Gld'],
  ' Lite': ['(?<!\\s)Lite'],
  ' XLite': ['(?<!\\s)XLite'],
  ' XXLite': ['(?<!\\s)XXLite'],
  NoLogo: ['NoLogo', 'No Logo'],
  Fretless: ['Fretless', 'FRETLESS', 'FRTLESS', 'FRTLSS'],
  NoFBS: ['NoFBS', 'NOFBS'],
  LinedFretless: ['LinedFretless', 'Lined Fretless', 'LINED FRETLESS'],
};

const BODY_NORMALIZED_TERMS = {
  ...UNIVERSAL_REPLACEMENT_TERMS,
  ...BODY_WOOD_MAP,
  ...BODY_WOOD_WEIGHTS,
  '8/6ST': ['8/6ST', '8/6St', '8/6 St'],
  '10ST': ['10ST', '10St'],
  '2Pc': ['2Pc', '2 Pc', '2Parts', 'TwoPiece'],
  '2Pots': ['2Pots', '2 Pots', '2POTS', 'TwoPots'],
  '3Pc': ['3Pc', '3 Pc', '3Parts', 'ThreePiece'],
  '3Pots': ['3Pots', '3 Pots', '3POTS', 'ThreePots'],
  '3Way': ['3Way', '3 Way', '3WaySw', '3W Switch'],
  '5Way': ['5Way', '5 Way', '5WaySw', '5W Switch'],
  '60s J/J': ["60'sJ/J", "60'sJJ", '60sJJ', '60sJ/J', '60J/J'],
  '60s P/J': ["60'sP/J", "60'sPJ", '60sPJ', '60sP/J', '60P/J'],
  '70s J/J': ["70'sJ/J", "70'sJ/J", '70sJJ', '70sJ/J', '70J/J'],
  '70s P/J': ["70'sP/J", "70'sP/J", '70sPJ', '70sP/J', '70P/J'],
  'EMG-35': ['EMG35', 'EMG-35', 'EMG 35'],
  'EMG-40': ['EMG40', 'EMG-40', 'EMG 40'],
  'HT F': ['HT F', 'FHT', 'HTF'],
  'J/J': ['J/J', 'JJ'],
  'P/J': ['P/J$', 'PJ$'],
  ACrv: ['A.?Crv', 'A.?Carve', 'ArmCrv'],
  BlackVen: ['BlackVen', 'Black Ven', 'BlackVeneer'],
  CallahamFerrules: ['CallahamFerrules', 'Callaham Ferrules', 'CallFerrules'],
  CrvTop: ['CrvTop', 'Crv Top'],
  CtrlRout: ['CtrlRout', 'Ctrl Rout', 'Control Rout', 'Cust CtrlRout'],
  DoubleCut: ['DblCut', 'DBL CUT', 'DoubleCut', 'Double Cut'],
  DT: ['DT', 'BT'],
  FC: ['F\\/C'],
  FHole: ['FHole', 'F-Hole', 'F-HOLE', 'FHOLE', 'F Hole', 'NO F-HOLE', 'No F-Hole', 'NO F-HOLE', 'NO FHOLE'],
  FrontJack: ['FrontJack', 'Front Jack', 'FtJack', 'F-Jack', 'FJack'],
  GotohTrem: ['GotohTrem', 'Gotoh Trem', 'GOTOH TREM'],
  Graph: ['GraphiteRods', 'Graphite Rods', 'Graph', 'Graphite', '1Graph', 'GRPH', '1GRPH', '1 GRPH', '1GRAPH', '1 GRAPH'],
  HCrv: ['H.?Crv', 'H.?Carve', 'HeelCrv', 'HCrv', 'HeelCrv', 'Heel Carve', 'Heel Crv', 'CrvHeel', 'Crv Heel', 'Contour Heel'],
  HipShot: ['HipShot', 'Hip Shot', 'HIPSHOT'],
  JMTrem: ['JMTrem', 'JM Trem', 'JM TREM', 'JMTREM'],
  Jazzmaster: ['JazzMaster', 'Jazzmaster', 'JAZZMASTER'],
  JimD: ['JimD', 'Jim D'],
  LEFTY: ['Lefty', 'LEFTY', 'LFT', 'LEFT'],
  'LEFTY WoodCover': ['LEFTYWoodCover', 'LEFTY Wood Cover'],
  'LEFTY PHCap': ['LEFTYPHCap', 'LEFTY PH Cap'],
  Lb: ['Lb', 'LB', 'lb'],
  LPJr: ['LPJr', 'LP Jr', 'LP Jr.', 'LP/Jr'],
  ModelT: ['ModelT', 'Model-T', 'Model T'],
  NashvilleTOM: ['NashvilleTOM', 'Nashville TOM', 'NAshvilleTOM'],
  NeckPlate: ['NeckPlate', 'Neck Plate'],
  NoBatt: ['NoBatt', 'No Batt'],
  NoCtrl: ['NoCtrl', 'NoCtrlR', 'NoCtrls', 'NO Controls'],
  NoJack: ['NoJack', 'No Jack', 'NoFrontJack', 'No FrontJack', 'NoSideJack', 'No SideJack'],
  NoPG: ['NoPG', 'NoPickGuard', 'No PickGuard'],
  NoPU: ['NoPUs', 'NoPU', 'No PU', 'NO PU', 'NoPUs', "No PU's", "NO PU's", 'NPU', 'No Pu'],
  NoPots: ['NoPots', 'NoPOTS', 'NO POTS'],
  NoRO: ['NoRO', 'NoR/O', 'No RO', 'NO RO'],
  NoSTH: ['NoSTH', 'NO STH'],
  NoTrem: ['NoTrem', 'No Trem'],
  NoWireR: ['NoWireR', 'NoWIreR', 'No Wire R'],
  OffSet: ['OffSet', 'OFFSET'],
  PHCap: ['PHCap', 'PH Cap', 'PHC$', 'Peg Head Cap'],
  RC: ['[&_]R\\/C_'],
  RO: ['^RO', '^R/O'],
  SideJack: ['SideJack', 'Side Jack', 'SJack', 'S-Jack'],
  TopRO: ['TopRO', 'Top R/O', 'TopR/O'],
  WCrv: ['W.?Crv', 'W.?Carve', 'WstCrv'],
  _: ['\\s_', '_\\s'],
};

const NECK_NORMALIZED_TERMS = {
  ...UNIVERSAL_REPLACEMENT_TERMS,
  ...NECK_WOOD_MAP,
  ...INLAY_MAP,
  '4ST': ['4ST', '4St', '4 St'],
  '5ST': ['5ST', '5St', '5 St'],
  '6ST': ['6ST', '6St', '6 St'],
  '4/19/30': ['4/19/30', '4-19-30'],
  '4/20': ['4/20', '4-20'],
  '4/21/34': ['4/21/34', '4-21-34'],
  '4/21/40': ['4/21/40', '4-21-40'],
  '4/21': ['4/21', '4-21'],
  '4/22/34': ['4/22/34', '4-22-34'],
  '4/22': ['4/22', '4-22'],
  '4/24/30.5': ['4/24/30.5', '4-24-30.5'],
  '4/24/34': ['4/24/34', '4-24-34'],
  '4/24': ['4/24', '4-24'],
  '4/30.5': ['4/30.5', '4-30.5'],
  '5/20/34': ['5/20/34', '5-20-34'],
  '5/20/35': ['5/20/35', '5-20-35'],
  '5/20': ['5/20', '5-20'],
  '5/21/35': ['5/21/35', '5-21-35'],
  '5/22/34': ['5/22/34', '5-22-34'],
  '5/22/34.5': ['5/22/34.5', '5-22-34.5'],
  '5/22/35': ['5/22/35', '5-22-35'],
  '5/22': ['5/22', '5-22'],
  '5/24/34.5': ['5/24/34.5', '5-24-34.5'],
  '6/21/34': ['6/21/34', '6-21-34'],
  '6/24/34': ['6/24/34', '6-24-34'],
  '6/24/34.5': ['6/24/34.5', '6-24-34.5'],
  '6.25-20': ['6.25-20', '6.25/20'],
  '7.5-9.5': ['7.5-9.5', '7.5/9.5', '7.5 -9.5'],
  '10-12': ['10-12', '10/12', '10 -12'],
  '12-14': ['12-14', '12/14', '12 -14'],
  BlackBlock: ['BlackBlock', 'Black Block'],
  CSBlock: ['CSBlock', 'CS Block', 'CSBlock F', 'CS Blocks'],
  AbaloneBlock: ['AbaloneBlock', 'Abalone Block', 'AbaloneBlocks'],
  Graph: ['GraphiteRods', 'Graphite Rods', 'Graph', 'Graphite', '1Graph', 'GRPH', '1GRPH', '1 GRPH', '1GRAPH', '1 GRAPH'],
  DblGraph: ['DblGraph', 'Dbl Graph', 'DblGraph', '2 Graph', '2Graph', 'DBL GRPH', 'DBL Graph', 'DBLGRPH', 'DBLGRAPH'],
  Bind: ['Bnd', 'Binding', 'Bind'],
  NoFrets: ['NoFRETS', 'No Fret', 'No Frets'],
  CR: ['C\\.R', 'CR(?!.)'],
  CSGraph: ['CSGrph', 'CSGraph', 'CS Graph', 'CS Grph'],
  LinedFretless: ['Polystyrene Fret Lines', 'Fret\\s?Lines'],
  Paddle: ['Paddle', 'PADDLE'],
  PocketBlock: ['PocketBlocks', 'Pocket Blocks', 'PocketBlock', 'Pocket Block', '\\*PocketBlock\\*', '\\*PocketBlocks\\*'],
  PocketF: ['PocketF', 'Pocket F', '\\*PocketF\\*'],
  PocketS: ['PocketS', 'Pocket S', '\\*PocketS\\*', '\\*PocketS Only\\*'],
  LooseFrets: ['LooseFrets', '\\*LooseFrets\\*', 'Loose Frets'],
  LooseDots: ['LooseDots', '\\*LooseDots\\*', 'Loose Dots'],
  LooseFrets_LooseDots: ['LooseFrets/D', 'LooseFrets&D'],
  'Sk&B': ['Sk&B', 'Sk/B', 'SK&B', 'SK & B', 'SK/B'],
};

const normalizeCompoundRadius = (term: string): string => {
  // Match patterns like "7.25-9.5CR", "10 -12CR", "12/14CR", etc.
  const match = term.match(/(\d+\.?\d*)\s*[-\/]\s*(\d+\.?\d*)CR/i);
  if (!match) return term;

  return `${match[1]}-${match[2]}CR`;
};

const invertFretsPattern = (description: string): string => {
  const fretsPattern = /((Loose|No)Frets)\s?([0-9]{5}(?:SS|EVO|EG)?)/;
  return description.replace(fretsPattern, '$3_$1');
};

const sanitizeNeckPartDescription = (description: string): string => {
  let newDescription = description;
  newDescription = newDescription.replace(/\d+\.?\d*\s*[-\/]\s*\d+\.?\d*CR/ig, (match) => normalizeCompoundRadius(match));
  newDescription = invertFretsPattern(newDescription);
  // logic
  Object.entries(NECK_NORMALIZED_TERMS).forEach((termSet) => {
    const [term, matches] = termSet;
    const matcher = new RegExp(matches.join('|'), 'ig');
    newDescription = newDescription.replace(matcher, term);
  });

  return newDescription;
};
const sanitizeBodyPartDescription = (description: string): string => {
  let newDescription = description;
  // logic
  Object.entries(BODY_NORMALIZED_TERMS).forEach((termSet) => {
    const [term, matches] = termSet;
    const matcher = new RegExp(matches.join('|'), 'ig');
    newDescription = newDescription.replace(matcher, term);
  });
  return newDescription;
};
export const sanitizePartDescription = (description: string): string => {
  // remove extraneous or bad characters
  const _description = description
    .replace(/copy_/i, '')
    .replace(/&/g, '/')
    .replace(/\s\s/, ' ');
  const sanityFunction = _description.match(/^[G|B]N/) ? sanitizeNeckPartDescription : sanitizeBodyPartDescription;
  // sanitize and add meta chars to description
  return addMeta(sanityFunction(_description));
};

const inlayType = (partDescription: string) => {
  const inlayMatcher = /([a-z\s]+)(Frame[s]?|Crown[s]?|Block|Leaves|Ovals|F)\/([a-z]+)(S)/i;
  const inlayMatch = partDescription.match(inlayMatcher);

  if (!inlayMatch) {
    return [null, null];
  }

  const [match, frontMaterial, frontType, sideMaterial, sideType, ...rest] = inlayMatch;

  return { front: frontType, side: sideType };
};

const GEOMETRIC_KEYS = [
  // body keys
  'armCarve',
  'battery',
  'bentTop',
  'bmh',
  'bound',
  'bridge',
  'bridgePickup',
  'carve',
  'carveTop',
  'comfy',
  'control',
  'crvHeel',
  'fretCount',
  'fretwire.profile',
  'frontInlay.shape',
  'groundWire',
  'jackType',
  'lefty',
  'lefty',
  'middlePickup',
  'model',
  'model',
  'neckMountingHoles',
  'neckPickup',
  'neckPocket',
  'npo',
  'nut',
  'nutType',
  'pickguard',
  'radius',
  'revPh',
  'roundover',
  'scale',
  'sideInlay.shape',
  'skunk',
  'sth',
  'stringCount',
  'switch',
  'thickness',
  'tilt',
  'trAccess',
  'trType',
  'tra',
  'veneer',
  'vintage',
  'waistCarve',
  'weightReductionType',
  'wireRout',
];
export const getPartValue = (obj: any, path: string): any => path.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj);
export const setPartValue = (obj: any, path: string, value: any): void => {
  const pathParts = path.split('.');
  const lastKey = pathParts.pop();

  const lastObj = pathParts.reduce((prevObj, key) => {
    if (prevObj[key] === undefined) prevObj[key] = {};
    return prevObj[key];
  }, obj);

  lastObj[lastKey as string] = value;
};
export const checkGeometricDifferences = (childPart: any, parentPart: any) => GEOMETRIC_KEYS.map((key) => {
  const childValue = getPartValue(childPart, key);
  const parentValue = getPartValue(parentPart, key);
  if (key === 'bound') {
    return every([childValue, parentValue], (v) => v !== 'None') || every([childValue, parentValue], (v) => v === 'None');
  }
  // Deep equality check
  return JSON.stringify(childValue) === JSON.stringify(parentValue);
});

export const geometricDescriptionParts = (part: any) => {

};

export const isSamePart = (partA: ICustomerPart, partB: ICustomerPart): boolean => (configToDescription(partA.config) === configToDescription(partB.config));

export const descriptionToConfig = async (description: string, keepMeta: boolean = false): Promise<IConfigEntry[]> => {
  const configSnapshot = await getDocs(collection(db.firestore, db.part.config.terms));
  const configTerms = configSnapshot.docs.map((d) => d.data());

  const typesSnapshot = await getDocs(collection(db.firestore, db.part.config.types));
  const configTermTypes = typesSnapshot.docs.map((d) => d.data());
  
  // Only clean meta if keepMeta is false
  const splitTerms = (keepMeta ? description : cleanMeta(description)).split('_').map((t) => t.trim());

  const termsByType: Record<string, string[]> = {};

  splitTerms.forEach((term, index) => {
    // Split compound terms by forward slash
    const compoundTerms = term.split('/');

    compoundTerms.forEach((compoundTerm) => {
      // Only clean meta for comparison, but keep original term
      const _term = cleanMeta(compoundTerm);
      const configTerm = find(configTerms, (t: any) => t.term === _term);

      let termType = configTerm?.type ?? 'Unknown';

      // Handle GB and BN cases
      if (description.match(/[GB]N/)) {
        if (termType === 'Wood_Feature' || termType === 'Construction_Modifier') {
          termType = 'Wood_Feature';
        }
      } else if (description.match(/[GB]B/)) {
        if (compoundTerm === 'Vin') {
          termType = 'Model_Vintage';
        }
        if (termType === 'Wood_Feature' || termType === 'Construction_Modifier') {
          termType = 'Wood_Feature';
        }
      }

      // Use original term if keepMeta is true
      const finalTerm = keepMeta ? compoundTerm : (isMeta(compoundTerm) && configTerm?.coercion ? `*${configTerm?.coercion}*` : (configTerm?.coercion || compoundTerm));

      // Group terms by type
      if (!termsByType[termType]) {
        termsByType[termType] = [];
      }
      termsByType[termType].push(finalTerm);
    });
  });

  // Convert grouped terms to IConfigEntry array
  return Object.entries(termsByType).map(([type, terms]) => {
    const configTermType = configTermTypes.find((t) => t.label === type.replace(/_/g, ' '));
    const rank = configTermType?.rank ?? Infinity;

    // Sort terms based on the 'order' property
    const sortedTerms = configTermType?.order === 'alphabetic'
      ? terms.sort()
      : terms;

    return {
      terms: sortedTerms,
      type,
      rank,
    };
  });
};

export const aggregateTermsByType = (aggregateTerms: IConfigEntry[]): IConfigEntry[] => {
  const groupedTerms = aggregateTerms.reduce((acc, { terms, type }) => {
    acc[type] = acc[type] || [];
    acc[type].push(...terms);
    return acc;
  }, {} as Record<string, string[]>);

  return Object.entries(groupedTerms).map(([type, _aggregateTerms]) => ({
    terms: _aggregateTerms,
    type,
    // @ts-ignore -- enumeration error due to type being string not defined by keys from termTypes
    rank: termTypes[type],
  }));
};

export const orderTermsByRank = (config: IConfigEntry[], configTerms: IConfigEntry[] = []): string[] => {
  if (configTerms.length === 0) {
    // First sort the groups by rank
    const sortedGroups = [...config].sort((a, b) => (a.rank ?? Infinity) - (b.rank ?? Infinity));

    // Then flatten and sort terms within each group alphabetically
    return sortedGroups.reduce((acc, group) => {
      if (['Wood', 'Inlay', 'Pickups', 'Instrument_Profile'].includes(group.type)) {
        return [...acc, group.terms.filter((t) => t.length > 0).join('/')];
      }
      const sortedTerms = [...group.terms].sort();
      return [...acc, ...sortedTerms];
    }, [] as string[]);
  }

  const reclassifiedGroups = [...config];

  // Check each term against configTerms for reclassification
  config.forEach((group) => {
    group.terms.forEach((term) => {
      const configTerm = find(configTerms, (ct: any) => ct.term === term);

      if (configTerm) {
        // Apply coercion if specified
        const coercedTerm = configTerm.coercion || term;

        if (configTerm.type !== group.type) {
          // Remove term from its original group
          const originalGroupIndex = reclassifiedGroups.findIndex((g) => g.type === group.type);
          if (originalGroupIndex !== -1) {
            reclassifiedGroups[originalGroupIndex] = {
              ...reclassifiedGroups[originalGroupIndex],
              terms: reclassifiedGroups[originalGroupIndex].terms.filter((t) => t !== term),
            };
          }

          // Find or create group for the new type
          let targetGroup = find(reclassifiedGroups, (g) => g.type === configTerm.type);
          const termTypeRank = termTypes[configTerm.type];
          if (!targetGroup) {
            targetGroup = {
              type: configTerm.type,
              rank: termTypeRank,
              terms: [coercedTerm],
            };
            reclassifiedGroups.push(targetGroup);
          } else if (!targetGroup.terms.includes(coercedTerm)) {
            targetGroup.terms.push(coercedTerm);
          }
        } else {
          // Update term in existing group without changing type
          const groupIndex = reclassifiedGroups.findIndex((g) => g.type === group.type);
          if (groupIndex !== -1) {
            const termIndex = reclassifiedGroups[groupIndex].terms.indexOf(term);
            if (termIndex !== -1) {
              reclassifiedGroups[groupIndex].terms[termIndex] = coercedTerm;
            }
          }
        }
      }
    });
  });

  // Remove any empty groups
  const finalGroups = reclassifiedGroups.filter((group) => group.terms.length > 0);

  // Sort by rank and alphabetically within ranks
  return finalGroups
    .sort((a, b) => (a.rank ?? Infinity) - (b.rank ?? Infinity))
    .reduce((acc, group) => [
      ...acc,
      ...group.terms.sort((a, b) => cleanMeta(a).localeCompare(cleanMeta(b))),
    ], [] as string[]);
};

export const updateConfig = (config: IConfigEntry[], configTerms: IConfigTerm[] = []): IConfigEntry[] => {
  const partTerms = config.map((c) => ({ terms: c.terms, type: c.type })); // This will be an array of arrays

  const updatedConfigTerms = partTerms.flatMap((t: any) => {
    const { terms, type } = t;
    if (type === 'Wood') {
      const { core, top, veneer } = resolveWood(config);
      return [core, top, veneer].filter((_t) => _t !== '').map((_t) => {
        const woodConfigTerm = find(configTerms, (ct) => ct.term === cleanMeta(_t));
        if (!woodConfigTerm) return { term: _t, type: 'Unknown', rank: Infinity };
        const newTerm = woodConfigTerm.coercion || woodConfigTerm.term;
        return { ...woodConfigTerm, term: newTerm, rank: termTypes.Wood };
      });
    }
    if (type === 'Inlay') {
      const { front, side } = resolveInlay(config);
      return [front, side].filter((i) => i.material !== '' && i.shape !== '').map((i) => {
        try {
          const inlayTerm = `${i.material}${i.shape}`;
          const inlayConfigTerm = find(configTerms, (ct) => ct.term === cleanMeta(inlayTerm));
          if (!inlayConfigTerm) return { term: inlayTerm, type: 'Unknown', rank: Infinity };
          const newTerm = inlayConfigTerm.coercion || inlayConfigTerm.term;
          return { ...inlayConfigTerm, term: newTerm, rank: termTypes.Inlay };
        } catch (e) {
          console.log(config);
          return null;
        }
      });
    }
    if (type === 'Pickups') {
      const { neck, middle, bridge } = resolvePickups(config);
      return [neck, middle, bridge].map((p) => {
        if (p === '') return null;
        const pickupConfigTerm = find(configTerms, (ct) => ct.term === cleanMeta(p));
        if (!pickupConfigTerm) return { term: p, type: 'Unknown', rank: Infinity };
        // const newTerm = pickupConfigTerm.coercion || pickupConfigTerm.term;
        // return { ...pickupConfigTerm, term: newTerm, rank: termTypes.Pickups };
        return { ...pickupConfigTerm, rank: termTypes.Pickups };
      });
    }

    return terms.map((_t) => {
      if (_t === '') return null;
      const term = cleanMeta(_t);
      const configTerm = find(configTerms, (ct) => ct.term === term);
      if (!configTerm) return { term, type: 'Unknown', rank: Infinity } as unknown as IConfigTerm;
      const newTerm = configTerm.coercion || configTerm.term;
      return { ...configTerm, term: newTerm, rank: termTypes[configTerm.type] || Infinity };
    });
  }).filter((t) => t);

  const groupedByType = updatedConfigTerms.reduce((acc, term: { type: string, rank: number, term: string, coercion: string }) => {
    const { type, rank } = term;

    if (!acc[type]) {
      acc[type] = { type, terms: [], rank };
    }

    acc[type].terms.push(term.coercion || term.term);

    return acc;
  }, {} as Record<string, IConfigEntry>);

  return Object.values(groupedByType);
};

export const configToDescription = (config: IConfigEntry[]): string => {
  const orderedTerms = orderTermsByRank(config);
  return addMeta(orderedTerms.join('_'));
};

export const resolveParentSku = (parent: any): string => {
  if (typeof parent === 'string') return parent;
  if (typeof parent === 'object' && parent !== null && 'Sku' in parent) return parent.Sku;
  return '';
};

export const breakPartDescription = (config: IConfigEntry[]): string[] => {
  // First, find the archetype to determine part type
  const archetype = config.find((entry) => entry.type === 'Archetype')?.terms[0] || '';
  const isBody = archetype.match(/^(GB|BB)/);
  const isNeck = archetype.match(/^(GN|BN)/);

  if (!isBody && !isNeck) {
    return [configToDescription(config)];
  }

  // Sort the config entries by rank
  const orderedConfig = [...config].sort((a, b) => (a.rank ?? Infinity) - (b.rank ?? Infinity));

  if (isBody) {
    // For bodies, find the index after pickups
    const pickupsIndex = orderedConfig.findIndex((entry) => entry.type === 'Pickups');
    if (pickupsIndex === -1) {
      return [configToDescription(config)];
    }

    const firstPart = orderedConfig.slice(0, pickupsIndex + 1);
    const secondPart = orderedConfig.slice(pickupsIndex + 1);

    return [
      configToDescription(firstPart),
      configToDescription(secondPart),
    ];
  }

  if (isNeck) {
    // For necks, find the index after Neck_Shape_Nut
    const neckShapeIndex = orderedConfig.findIndex((entry) => entry.type === 'Neck_Shape_Radius');
    if (neckShapeIndex === -1) {
      return [configToDescription(config)];
    }

    const firstPart = orderedConfig.slice(0, neckShapeIndex + 1);
    const secondPart = orderedConfig.slice(neckShapeIndex + 1);

    return [
      configToDescription(firstPart),
      configToDescription(secondPart),
    ];
  }

  // Fallback case (shouldn't be reached due to earlier checks)
  return [configToDescription(config)];
};
