import { resolveFirestorePath } from './helper';

const records = resolveFirestorePath('orders');
const items = resolveFirestorePath('order-items');
const shipments = resolveFirestorePath('order-shipments');

export default {
  records,
  items,
  shipments,
};
