import React, { useEffect, useState, useTransition } from 'react';
import { getAuth } from 'firebase/auth';
import useFirebase from './index';
import { Spin } from 'antd';
import { LoadingWrapper } from 'shared/components/Utility/Loader';

interface AuthContextType {
  currentUser: any | null;
  userEmail: string | null;
  isLoading: boolean;
}

export const AuthContext = React.createContext<AuthContextType>({
  currentUser: null,
  userEmail: null,
  isLoading: true
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isPending, startTransition] = useTransition();
  const [currentUser, setCurrentUser] = useState<any|null>(null);
  const [userEmail, setUserEmail] = useState<string|null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      startTransition(() => {
        setCurrentUser(user);
        setUserEmail(user?.email ?? null);
        setIsLoading(false);
      });
    });

    return () => unsubscribe();
  }, []);

  if (isLoading || isPending) {
    return (
      <LoadingWrapper key="auth-loading">
        <Spin size="large" tip="Loading..." />
      </LoadingWrapper>
    );
  }

  return (
    <AuthContext.Provider value={{ currentUser, userEmail, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
