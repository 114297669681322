import { useContext, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { currentUserEmailAtom } from 'shared/state/routingState';
import { AuthContext } from 'vendor/Firebase/AuthProvider';

export const AuthStateSync = () => {
  const { userEmail } = useContext(AuthContext);
  const setCurrentUserEmail = useSetRecoilState(currentUserEmailAtom);

  useEffect(() => {
    setCurrentUserEmail(userEmail);
  }, [userEmail, setCurrentUserEmail]);

  return null;
}; 