import { atom } from 'recoil';
import { resolveRealtimePath, resolveFirestorePath } from 'shared/db/helper';
import { IShopOperator } from '../types/dbRecords';

export const RAW_MATERIALS_COLLECTION = atom({
  key: 'RAW_MATERIALS_COLLECTION',
  default: resolveFirestorePath('rawMaterials'),
});
export const NON_CONFORMANCE_COLLECTION = atom({
  key: 'NON_CONFORMANCE_COLLECTION',
  default: resolveFirestorePath('non-conformance'),
});
export const GL_CODES_COLLECTION = atom({
  key: 'GL_CODDES_COLLECTION',
  default: resolveFirestorePath('gl-codes'),
});
export const PRODUCT_CODES_COLLECTION = atom<string>({
  key: 'PRODUCT_CODES_COLLECTION',
  default: resolveFirestorePath('product-codes'),
});
export const ROUTER_DATA_PATH = atom({
  key: 'ROUTER_DATA_PATH',
  default: resolveRealtimePath('router'),
});

export const RECORD_NUMBERS_COLLECTION = atom({
  key: 'RECORD_NUMBERS_COLLECTION',
  default: resolveRealtimePath('recordNumbers'),
});

export const TERMS_DB_COLLECTION = atom({
  key: 'TERMS_DB_COLLECTION',
  default: 'terms',
  // default: resolveFirestorePath('terms'),
});

export const CREDIT_MEMO_DB_COLLECTION = atom({
  key: 'CREDIT_MEMO_DB_COLLECTION',
  default: resolveFirestorePath('credit-memos'),
});

export const shopOperatorsObjectAtom = atom <IShopOperator[]>({
  key: 'shopOperatorsAtom',
  default: [],
});

export const currentShopOperatorsListAtom = atom<IShopOperator[]>({
  key: 'currentShopOperatorsListAtom',
  default: [],
});

export const currentShopOperatorAtom = atom({
  key: 'currentShopOperatorAtom',
  default: '',
});

export const userSettingsAtom = atom({
  key: 'userSettingsAtom',
  default: {
    boolean: {
      useTestData: false,
      useWeightedPartsPerDay: false,
      showCNCLoad: false,
      showFinishingLoad: false,
      showDecimalWeights: false,
      showInventoryMargin: false,
    },
    string: {
      scrollToLocation: 'current',
    },
  },
});
