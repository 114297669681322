import React, { useCallback, useContext, useEffect, Suspense, lazy, useRef } from 'react';
import qs from 'qs';
import { Modal, Spin } from 'antd';
import 'antd/dist/antd.css';
import { includes } from 'lodash-es';
import { useRecoilValue } from 'recoil';
import { Font } from '@react-pdf/renderer';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import {
  BrowserRouter as Router, Route, Switch, useLocation,
  Redirect,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { AuthProvider, AuthContext } from 'vendor/Firebase/AuthProvider';
import { AuthStateSync } from 'shared/components/AuthStateSync';
import ContentWrapper from 'pages/ContentWrapper';
import useFirebase from 'vendor/Firebase';

import theme from 'shared/theme';
import { BODY_SIZE } from 'shared/typography/constants';
import PrivateRouteWithDeps from 'shared/components/Routing/PrivateRouteWithDeps';
import QBOAuth from 'shared/api/QBO/auth';
import SiteMenu from 'shared/pageElements/SiteMenu';
import { useTestDataRemote } from 'shared/util/site';
import { QBO_AUTH_PATH, QBO_USERS_ATOM } from 'shared/state/qboState';
import {
  adminUserEmailsAtom,
  customerUserEmailsAtom,
  scannerUserEmailsAtom,
  shopUserEmailsAtom, superAdminUserEmailAtom, supervisorEmailsAtom,
} from 'shared/state/routingState';

import RedHatDisplayLight from './assets/fonts/RedHatDisplay_Light.ttf';
import RedHatDisplayRegular from './assets/fonts/RedHatDisplay_Regular.ttf';
import RedHatDisplayBold from './assets/fonts/RedHatDisplay_Bold.ttf';
import RedHatDisplaySemiBold from './assets/fonts/RedHatDisplay_Semibold.ttf';

// Register each weight as its own font family
Font.register({ src: RedHatDisplayRegular, family: 'RedHatDisplay' });
Font.register({ src: RedHatDisplayLight, family: 'RedHatDisplay-Light' });
Font.register({ src: RedHatDisplayBold, family: 'RedHatDisplay-Bold' });
Font.register({ src: RedHatDisplaySemiBold, family: 'RedHatDisplay-SemiBold' });

// Lazy load components
const CreateEditArchetype = lazy(() => import('pages/ItemPricing/Components/CreateEditArchetype'));
const CreateEditOption = lazy(() => import('pages/ItemPricing/Components/CreateEditOption'));
const CreateEditWoodPart = lazy(() => import('pages/ItemPricing/Components/CreateEditWoodPart'));
const CustomerBrowser = lazy(() => import('pages/Customer/CustomerBrowser'));
const CustomerDetail = lazy(() => import('pages/Customer/CustomerDetail'));
const CustomerPriceList = lazy(() => import('pages/Customer/CustomerPriceList'));
const DataParsers = lazy(() => import('pages/Util/DataParsers'));
const EditPricing = lazy(() => import('pages/ItemPricing/EditPricing'));
const InventoryDetail = lazy(() => import('pages/Inventory/InventoryDetail'));
const InventoryList = lazy(() => import('pages/Inventory/InventoryList'));
const Login = lazy(() => import('pages/Auth/Login'));
const MessageCenterDrawer = lazy(() => import('pages/MessageCenter/MessageCenterDrawer'));
const NCFileAnalyzer = lazy(() => import('pages/Util/NCFileAnalyzer'));
const NameGame = lazy(() => import('pages/Util/NameGame'));
const OrdersList = lazy(() => import('pages/Orders/OrderRecords'));
const PartNonComplianceScanner = lazy(() => import('pages/QualityAssurance/NonCompliance/NonComplianceScanner'));
const PartRecord = lazy(() => import('pages/PartDetail/PartRecord'));
const PurchaseOrderBrowser = lazy(() => import('pages/PurchaseOrders/PurchaseOrderBrowser'));
const QATicketBrowser = lazy(() => import('pages/QualityAssurance/QATickets/QATicketBrowser'));
const QATicketDetail = lazy(() => import('pages/QualityAssurance/QATickets/QATicketDetail'));
const SalesOrder = lazy(() => import('pages/ProductionSchedule/SalesOrders'));
const SalesOrderRecord = lazy(() => import('pages/Orders/SalesOrderRecord'));
const SettingsDrawer = lazy(() => import('pages/Settings/SettingsDrawer'));
const ShipmentDetail = lazy(() => import('pages/Shipment/ShipmentDetail'));
const ShipmentList = lazy(() => import('pages/Shipment/ShipmentList'));
const ShopRouter = lazy(() => import('pages/ProductionSchedule/ShopRouter'));
const ShopScanner = lazy(() => import('pages/ProductionSchedule/ShopScanner'));
const ShopScannerManualEntry = lazy(() => import('pages/ProductionSchedule/ShopScannerManualEntry'));
const UniversalDensityCalculatorByPart = lazy(() => import('pages/Util/DensityCalculator/UniversalDensityCalculatorByPart'));
const VendorBrowser = lazy(() => import('pages/Vendor/VendorBrowser'));
const WorkOrderRecord = lazy(() => import('pages/Orders/WorkOrderRecord'));
const PartTerms = lazy(() => import('pages/ProductDevelopment/PartTerms'));
const ProductDevTasks = lazy(() => import('pages/ProductDevelopment/ProductDevTasks'));
const TestPDF = lazy(() => import('pages/PDF/TestPDF'));
import FirebaseStatus from './vendor/Firebase/FirebaseStatus';

// Add this after your other styled components
const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: ${theme.palette.neutral.white};

  .ant-spin {
    .ant-spin-dot-item {
      background-color: ${theme.palette.primary.hue};
    }
  }
`;

const AppSections = () => {
  const location = useLocation();
  const { database } = useFirebase();
  const adminEmails = useRecoilValue(adminUserEmailsAtom).emails;
  const supervisorEmails = useRecoilValue(supervisorEmailsAtom).emails;
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom).emails;
  const shopUserEmails = useRecoilValue(shopUserEmailsAtom).emails;
  const scannerEmails = useRecoilValue(scannerUserEmailsAtom).emails;
  const customerEmails = useRecoilValue(customerUserEmailsAtom).emails;
  const { currentUser } = useContext(AuthContext);

  const titles: Record<string, string> = {
    schedule: 'Production Plan',
    router: 'Shop Router',
    pricing: 'Pricing',
    'pricing/edit': 'Edit Pricing',
    orders: 'Orders',
    'util/parsers': 'Utilties',
    customers: 'Customers',
    shipments: 'Shipments',
    scanner: 'Shop Scanner',
    inventory: 'Inventory',
    vendors: 'Vendors',
    'purchase-orders': 'Purchase Orders',
    'nc/report': 'Non-Compliance',
    'nc/scanner': 'Non-Compliance',
    'pricing/new-parts': 'New Parts',
  };

  const searchTitles = {
    'orders/create': 'Order Detail',
    'inventory/edit': 'Inventory Detail',
  };

  useEffect(() => {
    const root = location.pathname.replace('/', '');
    let title = 'Production Plan';
    if (!location.search) {
      title = titles[root];
      document.title = title || 'Production Plan';
    } else {
      if (root === 'orders/create') {
        const { customer, salesOrder } = qs.parse(location.search.replace('?', ''));
        if (customer && salesOrder) title = `${customer} Order #${salesOrder} - Detail`;
        else title = 'Order Detail';
      } else if (root === 'inventory') {
        title = 'Inventory List';
      } else if (root === 'inventory/edit') {
        const { partId } = qs.parse(location.search.replace('?', ''));
        if (partId) title = `${partId} - Inventory Detail`;
        else title = 'Inventory Detail';
      } else if (root === 'shipments/edit') {
        title = 'Shipment Detail';
      } else if (root === 'pricing/body' || root === 'pricing/neck') {
        const { partId } = qs.parse(location.search.replace('?', ''));
        if (partId) title = `${partId} - Pricing Detail`;
        else title = 'Customer Part Detail';
      } else if (root === 'pd') {
        title = 'Product Development';
      }
      document.title = title || 'HELM';
    }
  }, [location.pathname]);

  return (
    <>
      <Suspense fallback={
        <LoadingWrapper>
          <Spin size="large" tip="Loading..." />
        </LoadingWrapper>
      }>
        <Switch location={location} key={location.pathname}>
          <Route key="login-root" exact path="/login">
            <Login key="login" />
          </Route>

          <Route key="test-pdf" exact path="/test-pdf">
            <TestPDF />
          </Route>
          {/* ------------------- ORDER MANAGEMENT --------------------- */}
          <PrivateRouteWithDeps key="orders-list" exact path="/orders" deps={['customers']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails]}>
            <OrdersList />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="add-sales-order" exact path="/orders/create" deps={['settings', 'inventory', 'configTerms', 'routerSteps', 'customers', 'shopOrderData']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <SalesOrderRecord />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="router-edit" exact path="/work-order/edit" deps={['settings', 'routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
            <WorkOrderRecord />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="router-edit" exact path="/router/edit" deps={['settings', 'routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
            <WorkOrderRecord />
          </PrivateRouteWithDeps>

          {/* ------------------- PRODUCTION SCHEDULE --------------------- */}

          <PrivateRouteWithDeps key="orders-at-root" exact path="/" deps={[]} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <Redirect to="/schedule/week" />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="orders-at-root" exact path="/schedule" deps={[]} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <Redirect to="/schedule/week" />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="orders-at-order-path" exact path="/schedule/week" deps={['settings', 'routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <SalesOrder />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="customer-orders-at-order-path" exact path="/schedule/customer" deps={['settings', 'routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <SalesOrder />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="next-up-orders-at-order-path" exact path="/schedule/up-next" deps={['settings', 'routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <SalesOrder />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="orders-at-order-path" exact path="/schedule/requirements" deps={['settings', 'routerSteps', 'customers', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <SalesOrder />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="router-root" exact path="/router" deps={['settings', 'routerSteps']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <ShopRouter />
          </PrivateRouteWithDeps>

          {/* ------------------- CUSTOMER --------------------- */}

          <PrivateRouteWithDeps key="customer-management" exact path="/customers" deps={['settings', 'customers', 'customerContacts']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <CustomerBrowser />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="customer-detail" exact path="/customers/detail" deps={['settings', 'shippingAddresses']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <CustomerDetail />
          </PrivateRouteWithDeps>

          {/* ------------------- SHIPMENT --------------------- */}

          <PrivateRouteWithDeps key="shipment-management" exact path="/shipments" deps={['settings', 'customers']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <ShipmentList />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="shipment-detail" exact path="/shipments/create" deps={['settings', 'routerSteps']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <ShipmentDetail />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="shipment-detail" exact path="/shipments/edit" deps={['settings', 'routerSteps']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <ShipmentDetail />
          </PrivateRouteWithDeps>

          {/* ------------------- CUSTOMER PART MANAGEMENT --------------------- */}

          <PrivateRouteWithDeps key="pricing-root" exact path="/pricing" deps={['settings', 'configTerms', 'customers', 'partData']} whitelist={[...superAdminEmails, ...adminEmails, ...customerEmails, ...supervisorEmails, 'edwardl@wildwoodmfg.com']}>
            <CustomerPriceList />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="pricing-edit" exact path="/pricing/edit" deps={['settings', 'configTerms', 'customers', 'partData', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <EditPricing />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="pricing-archetype-edit" exact path="/pricing/archetype/edit" deps={['settings']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <CreateEditArchetype />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="pricing-material-edit" exact path="/pricing/material/edit" deps={['settings', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <CreateEditWoodPart />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="pricing-option-edit" exact path="/pricing/option/edit" deps={['settings']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <CreateEditOption />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="pricing-neck" exact path="/pricing/neck" deps={['settings', 'configTerms', 'customers', 'partData', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, 'edwardl@wildwoodmfg.com']}>
            <PartRecord />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="pricing-body" exact path="/pricing/body" deps={['settings', 'configTerms', 'customers', 'partData', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, 'edwardl@wildwoodmfg.com']}>
            <PartRecord />
          </PrivateRouteWithDeps>
          {/* ------------------- INVENTORY MANAGEMENT --------------------- */}
          <PrivateRouteWithDeps key="inventory-list" exact path="/inventory" deps={['settings', 'inventory', 'glCodes', 'productCodes']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <InventoryList />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="inventory-detail" exact path="/inventory/edit" deps={['settings', 'inventory', 'glCodes', 'productCodes', 'routerSteps', 'vendors']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <InventoryDetail quickEdit={false} />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="inventory-create" exact path="/inventory/new" deps={['settings', 'inventory', 'glCodes', 'productCodes', 'routerSteps', 'vendors']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails]}>
            <InventoryDetail quickEdit={false} />
          </PrivateRouteWithDeps>

          {/* ------------------- PURCHASE ORDERS --------------------- */}
          <PrivateRouteWithDeps key="purchase-order-list" exact path="/purchase-orders" deps={['settings', 'inventory', 'purchaseOrders']} whitelist={[...superAdminEmails, ...adminEmails, 'edwardl@wildwoodmfg.com']}>
            <PurchaseOrderBrowser />
          </PrivateRouteWithDeps>

          {/* ------------------- VENDOR MANAGEMENT --------------------- */}
          <PrivateRouteWithDeps key="vendor-list" exact path="/vendors" deps={['settings', 'vendors']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails]}>
            <VendorBrowser />
          </PrivateRouteWithDeps>
          {/* <PrivateRouteWithDeps key="vendor-detail" exact path="/vendor/:vendorCode" deps={['vendors']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails]}> */}
          {/*  <VendorDetail /> */}
          {/* </PrivateRouteWithDeps> */}

          {/* ------------------- UTILITY --------------------- */}

          <PrivateRouteWithDeps key="parsers-list" exact path="/util/parsers" deps={['settings', 'configTerms', 'inventory', 'routerSteps', 'customers']} whitelist={[...superAdminEmails, 'christineh@wildwoodmfg.com']}>
            <DataParsers />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="nc-file-analyzer" exact path="/util/nc-file-analyzer" deps={[]} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <NCFileAnalyzer />
          </PrivateRouteWithDeps>
          <Route key="udc-deprecated" exact path="/util/udc">
            <Redirect to="/tools/udc" />
          </Route>
          <PrivateRouteWithDeps key="weight-chart-by-part" exact path="/util/density-calculator" deps={['settings', 'customers']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <UniversalDensityCalculatorByPart />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="name-game" exact path="/util/name-game" deps={['settings', 'configTerms']} whitelist={[...superAdminEmails, ...adminEmails, ...supervisorEmails, ...shopUserEmails]}>
            <NameGame />
          </PrivateRouteWithDeps>

          {/* ------------------- SCANNERS --------------------- */}

          {/* ------------------- ROUTER SCANNER --------------------- */}
          <PrivateRouteWithDeps key="scanner-root" exact path="/scanner" deps={['settings', 'routerSteps', 'inventory']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
            <ShopScanner />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="scanner-manual" exact path="/scanner/manual-entry" deps={['settings', 'routerSteps']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
            <ShopScannerManualEntry />
          </PrivateRouteWithDeps>

          {/* ------------------- QUALITY ASSURANCE & NON_COMPLIANCE --------------------- */}
          <PrivateRouteWithDeps key="qa-ticket-browser" exact path="/qa/tickets" deps={['settings']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails]}>
            <QATicketBrowser />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="qa-ticket-new" exact path="/qa/ticket/new" deps={['settings', 'customers', 'routerSteps', 'operatorData', 'ncConfig']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails]}>
            <QATicketDetail />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="qa-ticket-edit" exact path="/qa/ticket/edit" deps={['settings', 'customers', 'routerSteps', 'operatorData', 'ncConfig']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails]}>
            <QATicketDetail />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="non-compliance-scanner" exact path="/nc/scanner" deps={['settings']} whitelist={[...superAdminEmails, ...adminEmails, ...shopUserEmails, ...supervisorEmails, ...scannerEmails]}>
            <PartNonComplianceScanner />
          </PrivateRouteWithDeps>

          {/* ------------------- NEW PARTS --------------------- */}
          <PrivateRouteWithDeps key="new-parts" exact path="/pd/queue" deps={['settings', 'openOrders', 'configTerms']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <ProductDevTasks />
          </PrivateRouteWithDeps>
          <PrivateRouteWithDeps key="part-terms" exact path="/pd/part-terms" deps={['settings', 'configTerms']} whitelist={[...superAdminEmails, ...adminEmails]}>
            <PartTerms />
          </PrivateRouteWithDeps>

        </Switch>
      </Suspense>
    </>
  );
};

const App = () => {
  const { database } = useFirebase();
  const qboAuthPath = useRecoilValue(QBO_AUTH_PATH);
  const { currentUser } = useContext(AuthContext);
  const qboUserEmails = useRecoilValue(QBO_USERS_ATOM);
  const qboInitRef = useRef(false);

  // Initial QBO auth check - only runs once
  useEffect(() => {
    if (!qboInitRef.current && currentUser && includes(qboUserEmails, currentUser.email)) {
      qboInitRef.current = true;
      QBOAuth.refreshToken(database, qboAuthPath)
        .catch((e) => {
          Modal.error({
            title: 'QBO Connection Error',
            content: 'Something went wrong with the connection to Quickbooks Online. Please let Keith know.',
          });
        });
    }
  }, [currentUser]);

  const GlobalStyle = createGlobalStyle`
    html {
      font-size: ${BODY_SIZE}px;
    }
    body {
      background-color: ${useTestDataRemote ? 'rgba(255, 0, 0, 0.075)' : window.location.href.match(/nc-scanner/) ? '#F0F0F0' : '#FFF'};
      & p {
        color: ${useTestDataRemote ? '#FF0000' : 'unset'};
      }
      & h3 {
        color: ${useTestDataRemote ? '#FF0000' : 'unset'};
      }
      & h6 {
        color: ${useTestDataRemote ? '#FF0000' : 'unset'};
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${useTestDataRemote ? '#FF0000' : '#1E62D3'};
      border-color: ${useTestDataRemote ? '#FF0000' : '#1E62D3'};
    }
    .ant-btn-primary {
      background-color: ${useTestDataRemote ? '#7a211b' : '#1E62D3'};
      border-color: ${useTestDataRemote ? '#7a211b' : '#1E62D3'};
      
      &:hover {
        border-color: ${useTestDataRemote ? '#c72d22' : '#1E62D3'};
        background-color: ${useTestDataRemote ? '#c72d22' : '#1E62D3'};
        filter: brightness(95%);
      }
    }
    .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background-color: ${useTestDataRemote ? '#FF0000' : '#1E62D3'};
      border-color: ${useTestDataRemote ? '#FF0000' : '#1E62D3'};

      &:hover {
        border-color: ${useTestDataRemote ? '#FF0000' : '#1E62D3'};
        background-color: ${useTestDataRemote ? '#FF0000' : '#1E62D3'};
        filter: brightness(95%);
      }
    }
    .ant-menu-submenu-title, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
      & a {
        color: ${useTestDataRemote ? '#FF0000' : '#1E62D3'};
      }
      &:hover {
        color: ${useTestDataRemote ? '#FF0000' : '#1E62D3'};
        //border-bottom: 2px solid ${useTestDataRemote ? '#FF0000' : '#1E62D3'};
      }
    }
  `;

  return (
    <RecoilRoot>
      <AuthProvider>
        <AuthStateSync />
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <SiteMenu />
            <MessageCenterDrawer />
            <SettingsDrawer />
            <FirebaseStatus />
            <ContentWrapper>
              <AppSections />
            </ContentWrapper>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </RecoilRoot>
  );
};

export default App;
