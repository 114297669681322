import { atom } from 'recoil';
import { Timestamp } from 'firebase/firestore';
import { useTestData } from 'shared/util/site';
import { resolveRealtimePath, resolveFirestorePath } from 'shared/db/helper';
import { IOrderItem, IShipment } from '../types/dbRecords';
import { IShippingDetail } from '../types/jb';

import { ISalesOrder, IShipOrder, IShopOrder } from '../../pages/Orders/types';

export const NEXT_ORDER_NUMBER_PATH = atom<string>({
  key: 'NEXT_ORDER_NUMBER_PATH',
  default: `${resolveRealtimePath('/recordNumbers')}/order`,
});

export const showOrderItemEditDrawerAtom = atom<boolean>({
  key: 'showOrderItemEditDrawerAtom',
  default: false,
});
export const activeOrderDisplayAtom = atom<'active'|'inactive'|'all'>({
  key: 'ActiveOrderDisplayAtom',
  default: sessionStorage.getItem('activeOrderDisplayType') as 'active'|'inactive'|'all' || 'all',
});
export const orderViewTypeAtom = atom<'week'|'customer'|'soon'|'requirements'>({
  key: 'orderViewTypeAtom',
  default: localStorage.getItem('salesOrderViewType') as 'week'|'customer'|'soon'|'requirements' || 'week',
});

export const orderPartViewTypeAtom = atom<'all'|'body'|'neck'>({
  key: 'orderPartViewTypeAtom',
  default: localStorage.getItem('partViewType') as 'all'|'body'|'neck' || 'all',
});

export const ORDERS_DB_COLLECTION = atom({
  key: 'ORDERS_DB_COLLECTION',
  default: resolveFirestorePath('orders'),
});
export const ORDER_ITEMS_DB_COLLECTION = atom({
  key: 'ORDER_ITEMS_DB_COLLECTION',
  default: resolveFirestorePath('order-items'),
});
export const ORDER_SHIPMENT_DB_COLLECTION = atom({
  key: 'ORDER_SHIPMENT_DB_COLLECTION',
  default: resolveFirestorePath('order-shipments'),
});

export const orderItemsAtom = atom<IOrderItem[]>({
  key: 'orderItemsListAtom',
  default: [],
});

export const currentOrderItemAtom = atom<string>({
  key: 'currentOrderItemAtom',
  default: '',
});
export const showAllOrderItemsAtom = atom<boolean>({
  key: 'showAllOrderItemsAtom',
  default: false,
});
export const workOrderPDFDrawerAtom = atom<boolean>({
  key: 'workOrderPDFDrawerAtom',
  default: false,
});
export const orderConfirmationPDFDrawerAtom = atom<boolean>({
  key: 'orderConfirmationPDFDrawerAtom',
  default: false,
});
export const invoicePDFDrawerAtom = atom<boolean>({
  key: 'invoicePDFDrawerAtom',
  default: false,
});
export const packSlipPDFDrawerAtom = atom<boolean>({
  key: 'packSlipPDFDrawerAtom',
  default: false,
});
export const orderShippingDataAtom = atom<IShippingDetail>({
  key: 'orderShippingDataAtom',
  default: {
    country: '',
    shippingAddress1: '',
    shippingAddress2: undefined,
    shippingCity: '',
    shippingState: '',
    shipZIP: '',
    PONumber: '',
    shipVia: '',
    termsCode: '',
  },
});
export const orderBillingDataAtom = atom({
  key: 'orderBillingDataAtom',
  default: {
    billingAddress1: '',
    billingCity: '',
    billingState: '',
    billingZIPCode: '',
    shippingCountry: '',
  },
});
export const currentPageTitleAtom = atom<string>({
  key: 'currentPageTitleAtom',
  default: '',
});

export const showShipOrderDrawerAtom = atom<boolean>({
  key: 'showShipOrderDrawerAtom',
  default: false,
});

export const currentShipOrderAtom = atom<IShipOrder>({
  key: 'currentShipOrderAtom',
  default: {} as IShopOrder,
});
export const currentShopOrderAtom = atom<IShopOrder>({
  key: 'currentShopOrderAtom',
  default: {
    partCount: 0,
    completed: false,
    completedDate: null,
    customer: {
      id: '',
      name: '',
      CompanyName: '',
      shortName: '',
      pricedItems: 0,
      bodyDiscount: 0,
      neckDiscount: 0,
    },
    dateCreated: Timestamp.fromDate(new Date()),
    description: '',
    hold: false,
    id: '',
    ihs: false,
    linkedOrders: [],
    orderValue: 0,
    outsideFinishRequired: false,
    machine: 'any',
    purchaseOrder: '',
    releaseId: '',
    releaseConfirmed: null,
    releaseConfirmedBy: null,
    releaseDate: Timestamp.fromDate(new Date()),
    runners: [],
    salesOrder: '',
    orderDate: Timestamp.fromDate(new Date()),
    shipDate: Timestamp.fromDate(new Date()),
    shipDateHistory: [],
    shipped: false,
    type: 'body',
    toppedOrBound: false,
    quickbooksId: '',
    finishing: true,
    // weightReduction: 'none',
    weightReduction: {
      none: 0,
      cavity: 0,
      slot: 0,
      honeycomb: 0,
      carveTop: 0,
    },
  },
});

export const orderSearchQueryAtom = atom({
  key: 'orderSearchQueryAtom',
  default: sessionStorage.getItem('order.query.string') || '',
});

export const currentOrderPageAtom = atom<number>({
  key: 'currentOrderPageAtom',
  default: (() => {
    const currentPage = sessionStorage.getItem('currentOrderPage');
    if (currentPage) return parseInt(currentPage, 10);
    return 1;
  })(),
});

export const shopOrdersAtom = atom<ISalesOrder[]>({
  key: 'shopOrdersAtom',
  default: [] as ISalesOrder[],
});

export const orderItemsContainerExpandedAtom = atom<boolean>({
  key: 'orderItemsContainerExpanded',
  default: true,
});
export const openOrdersDataAtom = atom<IShopOrder[]>({
  key: 'openOrdersDataAtom',
  default: [],
});
export const jobScheduleDataAtom = atom({
  key: 'jobScheduleDataAtom',
  default: '',
});
export const allocatedNotConsumedDataAtom = atom({
  key: 'allocatedNotConsumedDataAtom',
  default: '',
});
export const orderScheduleDataAtom = atom({
  key: 'orderScheduleDataAtom',
  default: '',
});

export const orderShipmentsAtom = atom<IShipment[]>({
  key: 'orderShipmentsAtom',
  default: [],
});

export const workOrderUpdateLabelAtom = atom<string>({
  key: 'workOrderUpdateLabelAtom',
  default: 'Working...',
});

export const orderItemEditModalAtom = atom<{
  visible: boolean;
  item: IOrderItem | null;
}>({
  key: 'orderItemEditModalAtom',
  default: {
    visible: false,
    item: null,
  },
});
