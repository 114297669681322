import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from 'shared/theme';
import { H6 } from 'shared/typography';
import { Spin } from 'antd';

interface IComponent {
  radius?: number;
  borderSize?: number;
  label?: string;
  fontSize?: string;
}

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props: { height: string }) => props.height};
  width: ${(props: { width: string }) => props.width};
  background-color: ${theme.palette.neutral.transparent};

  .ant-spin {
    .ant-spin-dot-item {
      background-color: ${(props: { invert: boolean }) => props.invert ? theme.palette.neutral.white : theme.palette.primary.hue};
    }
  }
`;

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(Spin)`
  background-color: ${theme.palette.neutral.transparent};
  .ant-spin-dot-item {
    background-color: ${theme.palette.primary.hue};
  }
`;

const SpinnerText = styled(H6)`
  margin-top: 12px;
  font-size: ${(props: { fontSize: string }) => props.fontSize};
`;

interface IComponent {
  height?: string;
  width?: string;
  invert?: boolean;
}

const Loader = ({ height = '100vh', width = '100%', invert = false }: IComponent) => (
  <LoadingWrapper height={height} width={width} invert={invert}>
    <Spinner size="large" tip="Loading..." />
  </LoadingWrapper>
);

export default Loader;
