import { resolveFirestorePath } from './helper';

const records = resolveFirestorePath('parts');
const bom = resolveFirestorePath('part-bom-data');
const pricing = resolveFirestorePath('part-pricing-data');
const history = resolveFirestorePath('part-history');
const config = {
  // we always want to use the prod environment for config
  // if needed for development, use `rewriteFirestorePath(db.part.config.terms, 'dev')` to get the dev environment
  terms: resolveFirestorePath('part-config-terms', 'prod'),
  types: resolveFirestorePath('part-config-term-types', 'prod'),
}

export default {
  records,
  bom,
  pricing,
  history,
  config,
};
