import { atom } from 'recoil';
import { resolveRealtimePath } from 'shared/db/helper';

export const QBO_USERS_ATOM = atom<string[]>({
  key: 'QBO_USERS_ATOM',
  default: [
    'keithh@wildwoodmfg.com',
    'cathyh@wildwoodmfg.com',
    'bruceh@wildwoodmfg.com',
    'darrans@wildwoodmfg.com',
    'christineh@wildwoodmfg.com',
    'edwardl@wildwoodmfg.com',
  ],
});

export const qboAuthUriAtom = atom<string>({
  key: 'qboAuthUriAtom',
  default: 'https://us-central1-admin-site-e7397.cloudfunctions.net/qboAPI/authUri',
});

export const qboRetrieveTokenUriAtom = atom<string>({
  key: 'qboRetrieveTokenUriAtom',
  default: 'https://us-central1-admin-site-e7397.cloudfunctions.net/qboAPI/retrieveToken',
});

export const qboRefreshTokenUriAtom = atom<string>({
  key: 'qboRefreshTokenUriAtom',
  default: 'https://us-central1-admin-site-e7397.cloudfunctions.net/qboAPI/refreshAccessToken',
});

export const qboAuthRefreshTokenAtom = atom<string>({
  key: 'qboAuthRefreshTokenAtom',
  default: '',
});

export const QBO_AUTH_PATH = atom({
  key: 'QBO_AUTH_PATH',
  default: resolveRealtimePath('auth'),
});
