import { atom } from 'recoil';
import { IVendor } from 'shared/types/vendor';
import { resolveFirestorePath } from 'shared/db/helper';

export const VENDOR_COLLECTION = atom({
  key: 'VENDOR_COLLECTION',
  default: resolveFirestorePath('vendors'),
});

export const vendorRecordsAtom = atom<IVendor[]>({
  key: 'vendorRecordAtom',
  default: [],
});

export const vendorSearchQueryAtom = atom({
  key: 'vendorSearchQueryAtom',
  default: sessionStorage.getItem('vendor.query.string') || '',
});

export const currentVendorPageAtom = atom({
  key: 'currentVendorPageAtom',
  default: 1,
});

export const activeVendorDisplayAtom = atom<'active'|'inactive'|'all'>({
  key: 'ActiveVendorDisplayAtom',
  default: sessionStorage.getItem('activeVendorDisplayType') as 'active'|'inactive'|'all' || 'all',
});
