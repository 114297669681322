import React, { useContext, useCallback } from 'react';
import { includes } from 'lodash-es';
import { Route, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  adminUserEmailsAtom,
  customerUserEmailsAtom,
  scannerUserEmailsAtom,
  shopUserEmailsAtom, superAdminUserEmailAtom,
} from 'shared/state/routingState';
import useDependencies from 'hooks/useDependencies';
import { FlexColumn } from 'shared/containers/FlexContainer';
import Loader from 'shared/components/Utility/Loader';
import { AuthContext } from 'vendor/Firebase/AuthProvider';

interface IComponent {
  exact: boolean;
  path: string;
  deps: string[];
  children: React.ReactNode;
  whitelist: string[];
}

const PrivateRouteWithDeps = ({
  exact, path, deps, children, whitelist,
}: IComponent) => {
  const depsReady = useDependencies(deps);
  const adminEmails = useRecoilValue(adminUserEmailsAtom);
  const superAdminEmails = useRecoilValue(superAdminUserEmailAtom);
  const shopUserEmails = useRecoilValue(shopUserEmailsAtom);
  const scannerStationEmails = useRecoilValue(scannerUserEmailsAtom);
  const customerEmails = useRecoilValue(customerUserEmailsAtom);
  const { currentUser } = useContext(AuthContext);

  // Generate stable keys using path instead of shortid
  const routeKey = `private-route-${path}`;
  const loaderKey = `loader-${path}`;

  const redirectUrlFor = useCallback((userEmail) => {
    if (includes([...superAdminEmails.emails, ...adminEmails.emails], userEmail)) return '/';
    if (includes(shopUserEmails.emails, userEmail)) return '/';
    if (includes(scannerStationEmails.emails, userEmail)) return '/scanner';
    if (includes(customerEmails.emails, userEmail)) return '/part-finder';
    return '/login';
  }, [superAdminEmails, adminEmails, shopUserEmails, scannerStationEmails, customerEmails]);

  return (
    <>
      {currentUser && includes(whitelist, currentUser.email) ? (
        <>
          {!depsReady() ? (
            <FlexColumn key={loaderKey} style={{ width: '100%', height: '50vw' }}>
              <Loader />
            </FlexColumn>
          ) : (
            <Route
              key={routeKey}
              exact={exact}
              path={path}
              render={(routeProps) => children}
            />
          )}
        </>
      ) : (
        <Redirect to={redirectUrlFor(currentUser?.email)} />
      )}
    </>
  );
};

export default React.memo(PrivateRouteWithDeps);
