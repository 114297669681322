import { DbInstance } from '../types/firebase';
 
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';
import { app } from 'vendor/Firebase';

import accounting from 'shared/db/accounting';
import customer from 'shared/db/customer';
import inventory from 'shared/db/inventory';
import order from 'shared/db/orders';
import part from 'shared/db/parts';
import purchaseOrder from 'shared/db/purchaseOrders';
import qualityAssurance from 'shared/db/qualityAssurance';
import realtime from 'shared/db/realtime';
import vendor from 'shared/db/vendor';

const db: DbInstance = {
  firestore: getFirestore(app),
  database: getDatabase(app),
  accounting,
  customer,
  inventory,
  order,
  part,
  purchaseOrder,
  qualityAssurance,
  realtime,
  vendor,
};

export default db;