import {
  difference, find, includes, omit, sortBy,
} from 'lodash';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { devLog } from 'shared/util/logging';
import { firestore } from 'vendor/Firebase';
import db from 'shared/db/index';
import { resolveFirestorePath } from 'shared/db/helper';
import {
  bodyDataAtom, neckDataAtom, newBodyAtom, newNeckAtom,
} from 'shared/state/pricingState';
import { CONSTRUCTION_JASON } from 'shared/scanner';
import { ICustomerRecord } from 'shared/types/dbRecords';
import { IRateOption, IUnitOption } from 'shared/types/pricingTool';
import { IShopOrder } from 'pages/Orders/types';
import { uniqueChildPartNameSegments } from 'shared/text';
import {
  configToDescription, getPartValue, setPartValue, updateConfig,
} from 'shared/partParser/util';
import {
  IConfigEntry, IConfigTerm, IConfigTermType, ICustomerPart,
} from 'shared/types/parts';
import { collection, query, where, getDocs, doc, updateDoc, writeBatch } from 'firebase/firestore';

interface IPrice {
  price: number;
  discount: number;
}

export const queryActiveCollection = async (collectionPath: string) => {
  const q = query(
    collection(db.firestore, collectionPath), 
    where('active', '==', true)
  );
  const docs = await getDocs(q);
  return docs;
};

export const definedParts = (list: any) => list.filter((i: any) => !!i);

export const formatPercent = (value: number, precision: number = 2, float: boolean = true) => {
  if (float) return `${(value * 100).toFixed(precision)}%`;
  return `${value.toFixed(precision)}%`;
};

export const formatMargin = (purchaseCost: number, sellPrice: number) => {
  const margin = (sellPrice - purchaseCost) / sellPrice;
  return formatPercent(margin);
};
export const formatPrice = (price: string|number|undefined, decimalPlaces: number = 2): string => {
  if (!price) return '$0.00';
  // guard against some prices being stored as strings
  const _price = typeof (price) === 'number' ? price : parseFloat(price.replace('$', ''));
  const format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
  // if (_price < 0) return `($${(_price * -1).toFixed(2)})`;
  // return `$${_price.toFixed(2)}`;
  return format.format(_price);
};

// export const partType = (part: any): string => {
//   if (_.includes(['neck', 'BN', 'GN'], part.type)) return 'neck';
//   return 'body';
// };

export const bodyTotal = (bodyState: any): IPrice => {
  if (!bodyState) return { price: 0, discount: 0 };
  const archetype = bodyState.archetype;
  // @ts-ignore
  let bodyCost = bodyState.bodyWood ? bodyState.bodyWood.price[archetype.instrument] : 0;
  // let materialCost = bomItems.map((b) => b.totalPrice).reduce((a, b) => a + b, 0);
  if (archetype.instrument === 'S' && bodyCost === 0) bodyCost = bodyState.bodyWood.price.M || 0;
  bodyCost += bodyState.blankModification?.price || 0;
  // @ts-ignore
  const topCost = bodyState.topWood && bodyState.topWood.price ? bodyState.topWood.price[archetype.instrument] : 0;

  const unitOptionsCost = [
    ...bodyState.options.weightReductionOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.constructionOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.accessoryOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.finishingOptions.filter((i: IUnitOption) => i.price >= 0),
    ...bodyState.options.handlingOptions.filter((i: IUnitOption) => i.price >= 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  // Calculate discounts separately so we don't discount our deductions from the price of a part.
  const unitOptionsDiscount = [
    ...bodyState.options.weightReductionOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.constructionOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.accessoryOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.finishingOptions.filter((i: IUnitOption) => i.price < 0),
    ...bodyState.options.handlingOptions.filter((i: IUnitOption) => i.price < 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  const laborOptionsCost = bodyState.options.laborOptions
    .map((o: IRateOption) => (o.quantity * o.price))
    .reduce((a: number, b: number) => a + b, 0);

  const priceAdjustment = bodyState.priceAdjustment || 0;

  // devLog('data/index', 90, `part total: ${archetype.price + bodyCost + topCost + unitOptionsCost + laborOptionsCost + priceAdjustment}`);
  // devLog('data/index', 90, `archetypeCost: ${archetype.price}`);
  // devLog('data/index', 90, `bodyCost: ${bodyCost}`);
  // // console.log('bodyCost', bodyCost);
  // devLog('data/index', 90, `unitOptionsCost: ${unitOptionsCost}`);
  // devLog('data/index', 90, `laborOptionsCost: ${laborOptionsCost}`);
  // devLog('data/index', 90, `priceAdjustment: ${priceAdjustment}`);

  return {
    price: archetype.price + bodyCost + topCost + unitOptionsCost + laborOptionsCost + priceAdjustment,
    discount: unitOptionsDiscount,
  };
};

export const neckTotal = (neckState: any): IPrice => {
  if (!neckState.archetype) return { price: 0, discount: 0 };
  const archetype = neckState.archetype;
  // @ts-ignore
  const neckCost = neckState.neckWood ? neckState.neckWood.price[archetype.instrument] : 0;
  // @ts-ignore
  const fretboardCost = neckState.fretboardWood ? neckState.fretboardWood.price[archetype.instrument] : 0;
  const unitOptionsCost = [
    ...(neckState.options?.trussRodOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options?.frettingOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options?.inlayOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options?.dotOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options?.constructionOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options?.finishingOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options?.handlingOptions || []).filter((i: IUnitOption) => i.price >= 0),
    ...(neckState.options?.accessoryOptions || []).filter((i: IUnitOption) => i.price >= 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  const unitOptionsDiscount = [
    ...(neckState.options?.trussRodOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options?.frettingOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options?.inlayOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options?.dotOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options?.constructionOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options?.finishingOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options?.handlingOptions || []).filter((i: IUnitOption) => i.price < 0),
    ...(neckState.options?.accessoryOptions || []).filter((i: IUnitOption) => i.price < 0),
  ].map((i: IUnitOption) => i.price).reduce((a, b) => a + b, 0);

  const laborOptionsCost = neckState.options.laborOptions
    .map((o: IRateOption) => (o.quantity * o.price))
    .reduce((a: number, b: number) => a + b, 0);

  const priceAdjustment = neckState.priceAdjustment || 0;

  // const archetypePrice = includes(SPECIAL_CUSTOMERS, currentCustomer.id) ? archetype.price - ARCHETYPE_DISCOUNT : archetype.price;
  return {
    price: archetype.price + neckCost + fretboardCost + unitOptionsCost + laborOptionsCost + priceAdjustment,
    discount: unitOptionsDiscount,
  };
};

export const discountPrice = (price: { price: number, discount: number, customerDiscount: number, type: string }, divisor: number = 100, noFormat: boolean = false) => {
  if (!price || !price.price) return formatPrice(0);
  if (price.price < 0) return price;
  // const discount = price.type.match(/[G|B]B/i) ? customer.bodyDiscount : customer.neckDiscount : 0;
  const rawDiscount = (price.price * (1 + (price.customerDiscount) / 100));
  if (noFormat) return rawDiscount + (price.discount || 0);
  return formatPrice(Math.round((rawDiscount * divisor) / divisor) + (price.discount || 0));
};

export const groupItems = (collection: any) => {
  const groupedItems = collection.types.map((t: string) => ({
    type: t,
    items: collection.items.filter((i: any) => i.type === t),
  }));

  return groupedItems;
};

export const mapParentPricing = (parent: any, pricingData: any) => {
  if (!parent.pricing) return pricingData;
  const MATERIAL_KEYS = [
    'bodyWood',
    'topWood',
    'fretboardWood',
    'neckWood',
    'options.dotOptions',
    'options.frettingOptions',
    'options.inlayOptions',
  ];
  const OPTIONAL_KEYS = [
    'options.constructionOptions',
    // Add other optional keys here as needed
  ];

  // Start with all pricing data
  const mappedPricing = { ...pricingData };

  // Remove items from OPTIONAL_KEYS that have parentMapped set to false
  OPTIONAL_KEYS.forEach((k: string) => {
    const optionCategory = getPartValue(mappedPricing, k);
    if (optionCategory && optionCategory.items) {
      const filteredItems = optionCategory.items.filter((item: any) => item.parentMapped !== false);
      setPartValue(mappedPricing, k, { ...optionCategory, items: filteredItems });
    }
  });

  // Map regular material keys from child
  MATERIAL_KEYS.forEach((k: string) => {
    const value = getPartValue(pricingData, k);
    if (value) {
      setPartValue(mappedPricing, k, value);
    }
  });

  return mappedPricing;
};

export const updateBodyPricing = (priceRecord: any, bodyData: any) => {
  try {
    // @ts-ignore
    priceRecord.archetype = find(
      definedParts(bodyData.archetype.items),
      (bA: any) => bA.id === priceRecord.archetype.id,
    ) || priceRecord.archetype;

    // @ts-ignore
    priceRecord.bodyWood = find(
      definedParts(bodyData.bodyWood.items),
      (tW: any) => tW.id === priceRecord.bodyWood.id,
    ) || priceRecord.bodyWood;

    priceRecord.blankModification = find(
      definedParts(bodyData.options.bodyBlankModifications.items),
      (b: any) => b.id === priceRecord.blankModification?.id,
    );

    // @ts-ignore
    priceRecord.topWood = find(
      definedParts(bodyData.topWood.items),
      (tW: any) => tW.id === priceRecord.topWood.id,
    ) || { id: '', label: 'Select a top wood (optional)', price: { L: 0, M: 0, S: 0 } };

    Object.entries(priceRecord.options).forEach((value: [string, unknown]) => {
      const [key, optionSet] = value;
      if (key !== 'laborOptions') {
        // if it's not a labor option, the value/quantity is always the same on both the
        // part record and the database, since it is a flat, binary value. In this case,
        // it's fine to stomp on the priceRecord's options.
        // @ts-ignore
        const mergedUnitOptionRecords = optionSet.map((o: IUnitOption) => find(definedParts(bodyData.options[key].items),
          (u: IUnitOption) => u.id === o.id));
        priceRecord.options[key] = mergedUnitOptionRecords.filter((o: IUnitOption) => o);
      } else {
        // in the case of Labor options, we only want to update the option value, since the quantity
        // may be different for each object.
        // @ts-ignore
        const mergedLaborOptionRecords = optionSet.map((o: IUnitOption) => {
          const dbRecord = find(definedParts(bodyData.options[key].items), (u: IUnitOption) => u.id === o.id);
          if (dbRecord) {
            return {
              ...o,
              price: dbRecord.price,
            };
          }
          return undefined;
        });
        priceRecord.options[key] = mergedLaborOptionRecords.filter((o: IUnitOption) => o);
      }
    });

    // console.log(bodyData);
    // console.log('----------');
    // console.log(priceRecord);
    return priceRecord;
  } catch (e) {
    console.log(e);
    console.log('----------------------------');
    console.log(priceRecord);
    console.log(bodyData);
    return null;
    // throw new Error('fuck dis shit');
  }
};

export const updateNeckPricing = (priceRecord: any, neckData: any) => {
  try {
  // @ts-ignore
    priceRecord.archetype = find(
      definedParts(neckData.archetype.items),
      (bA: any) => bA.id === priceRecord.archetype.id,
    ) || priceRecord.archetype;

    // @ts-ignore
    priceRecord.neckWood = find(
      definedParts(neckData.neckWood.items),
      (bW: any) => bW.id === priceRecord.neckWood.id,
    ) || priceRecord.neckWood;

    // @ts-ignore
    priceRecord.fretboardWood = find(
      definedParts(neckData.fretboardWood.items),
      (tW: any) => tW.id === priceRecord.fretboardWood.id,
    ) || priceRecord.fretboardWood;

    Object.entries(priceRecord.options).forEach((value: [string, unknown]) => {
      const [key, optionSet] = value;
      // @ts-ignore
      const mergedRecords = optionSet.map((o: IUnitOption) => find(definedParts(neckData.options[key].items),
        (u: IUnitOption) => u.id === o.id));
      priceRecord.options[key] = mergedRecords.filter((o: IUnitOption) => o);
    });

    return priceRecord;
  } catch (e) {
    console.log(e);
    console.log('----------------------------');
    console.log(priceRecord);
    console.log(neckData);
    throw new Error('fuck dis shit');
  }
};

export const updatePricing = (partRecord: any, pricingData: any, partCategory: 'body'|'neck'): any => {
  // Don't immediately return null if no pricing - try to get pricing from pricingData first
  const [bodyData, neckData] = pricingData;

  let updated = {
    ...(partRecord.pricing || {}),
    discount: 0,
  };

  try {
    if (partRecord.partCategory === 'neck' || Object.keys(partRecord.pricing || {}).includes('neckWood')) {
      updated = updateNeckPricing(updated, neckData);
    } else {
      updated = updateBodyPricing(updated, bodyData);
    }

    if (!updated) {
      devLog('shared/data/index', 274, partRecord);
      // Return original pricing if update failed
      return { ...partRecord, pricing: partRecord.pricing };
    }

    return { ...partRecord, pricing: updated };
  } catch (e) {
    console.error('Error updating pricing:', e);
    // Return original pricing if there was an error
    return { ...partRecord, pricing: partRecord.pricing };
  }
};

export const sanitizePricingRecord = (pricingRecord: any) => {
  const validKeys = [
    'archetype',
    'bodyWood',
    'options',
    'topWood',
    'weightClasses',
    'fretboardWood',
    'neckWood',
    'priceAdjustment',
    'blankModification',
  ];
  const invalidKeys = difference(Object.keys(pricingRecord), validKeys);
  return omit(pricingRecord, invalidKeys);
};

const isEqual = (obj1: any, obj2: any): boolean => {
  // Handle primitive types and exact equality
  if (obj1 === obj2) return true;

  // Handle null/undefined cases
  if (obj1 == null || obj2 == null) return obj1 === obj2;

  // Handle different types
  if (typeof obj1 !== typeof obj2) return false;

  // Handle arrays
  if (Array.isArray(obj1)) {
    if (!Array.isArray(obj2) || obj1.length !== obj2.length) return false;
    return obj1.every((item, index) => {
      try {
        return isEqual(item, obj2[index]);
      } catch {
        return false;
      }
    });
  }

  // Handle objects
  if (typeof obj1 === 'object') {
    try {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      return keys1.every((key) => {
        try {
          return isEqual(obj1[key], obj2[key]);
        } catch {
          return false;
        }
      });
    } catch {
      return false;
    }
  }

  // Handle remaining cases (primitives)
  return obj1 === obj2;
};

export const processPricingData = (
  partRecords: ICustomerPart[],
  pricingData: any,
  childViewType: 'sku' | 'like' = 'sku',
  onProgress?: (percent: number) => void,
) => {
  const updates: { [key: string]: any } = {};
  const totalRecords = partRecords.length;
  let processedCount = 0;

  const partPrices = partRecords.map((p: ICustomerPart) => {
    if (!p.pricing) {
      processedCount += 1;
      if (onProgress) {
        onProgress(50 + ((processedCount / totalRecords) * 50));
      }
      return p;
    }

    const parent = p.parent ? find(partRecords, (_p) => _p.Sku === p.parent) : null;
    const partPricing = parent ? mapParentPricing(parent, p.pricing) : p.pricing;

    devLog('shared/data/index', 375, `Pricing ${p.Sku}`);
    const partCategory = includes(['GN', 'BN'], p.type) ? 'neck' : 'body';
    const record = updatePricing({ ...p, pricing: partPricing, partCategory }, pricingData, partCategory);
    let rawPrice = record?.price || p.price || 0;

    if (record?.pricing) {
      const totalFunction = includes(Object.keys(record.pricing), 'neckWood') ? neckTotal : bodyTotal;
      rawPrice = totalFunction(record.pricing);
    }

    const customerDiscount = includes(['GB', 'BB'], p.type)
      ? parseInt((p.customer?.bodyDiscount || 0), 10)
      : parseInt((p.customer?.neckDiscount || 0), 10);
    const price = Math.round(discountPrice({ ...rawPrice, customerDiscount, type: p.type }, 100, true) as number);

    if (p.price !== price) {
      updates[p.Sku] = { price: Math.round(price) };
    }

    processedCount += 1;
    if (onProgress) {
      onProgress(50 + ((processedCount / totalRecords) * 50));
    }

    return { 
      ...p, 
      price,
      pricing: record?.pricing || p.pricing || null  // Preserve pricing data
    };
  });

  // Batch updates in chunks of 500 (Firestore limit)
  const batchSize = 500;
  const skus = Object.keys(updates);
  for (let i = 0; i < skus.length; i += batchSize) {
    const batch = writeBatch(db.firestore);
    const chunk = skus.slice(i, i + batchSize);

    chunk.forEach((sku) => {
      const docRef = doc(db.firestore, db.part.records, sku);
      batch.update(docRef, updates[sku]);
    });

    // Fire and forget - we don't need to wait for these updates
    batch.commit();
  }

  if (childViewType === 'sku') sortBy(partPrices, (p) => p.Sku);

  const childSkuPricing = partPrices.map((p) => {
    if (!p.parent) return { ...p, isChild: false, sortKey: p.Sku };

    const parentSku = p.parent.Sku;
    const parent = find(partPrices, (price) => price.Sku === parentSku);

    if (parent) {
      try {
        const partDifferences = uniqueChildPartNameSegments(configToDescription(parent.config), [p.Description], false)[0];
        return {
          ...p,
          isChild: true,
          sortKey: `${parent.Sku}.${p.Sku}`,
          uniqueModifiers: partDifferences,
        };
      } catch (e) {
        devLog('shared/data/index', 405, p);
        devLog('shared/data/index', 405, parent);
        return { ...p, isChild: false, sortKey: p.Sku };
      }
    }
    return { ...p, isChild: false, sortKey: p.Sku };
  });

  return sortBy(childSkuPricing, 'sortKey');
};

export const fetchPricing = async (
  customer: ICustomerRecord|null,
  configTerms: IConfigTerm[],
  pricingData: any = null,
  childViewType: 'sku'|'like' = 'sku',
  onProgress?: (percent: number) => void,
): Promise<any[]> => {
  const startTime = performance.now();
  console.log('🕒 Starting fetchPricing');

  // Create all queries upfront
  const queries = {
    parts: customer 
      ? query(collection(db.firestore, db.part.records), where('customer', '==', customer.DisplayName))
      : query(collection(db.firestore, db.part.records), where('customer', '!=', 'TESCUS')),
    pricing: customer
      ? query(
          collection(db.firestore, db.part.pricing),
          where('__name__', '>=', customer.DisplayName),
          where('__name__', '<', customer.DisplayName + '\uf8ff')
        )
      : query(collection(db.firestore, db.part.pricing)),
    customers: query(collection(db.firestore, db.customer.records), where('active', '==', true))
  };

  const queryStartTime = performance.now();
  // Execute all queries in parallel
  const [partDocs, pricingDocs, customerDocs] = await Promise.all([
    getDocs(queries.parts),
    getDocs(queries.pricing),
    getDocs(queries.customers)
  ]);
  console.log(`🕒 Firebase queries completed in ${(performance.now() - queryStartTime).toFixed(2)}ms`);
  console.log(`📊 Retrieved: ${partDocs.size} parts, ${pricingDocs.size} pricing records, ${customerDocs.size} customers`);

  const processingStartTime = performance.now();
  // Pre-process data into maps for faster lookups
  const customers = new Map(customerDocs.docs.map(d => {
    const data = d.data();
    return [data.DisplayName, data];
  }));

  // Create a map of pricing data with Sku as key
  const partPricing = new Map(pricingDocs.docs.map(d => [d.id, d.data()]));
  console.log(`🕒 Data pre-processing completed in ${(performance.now() - processingStartTime).toFixed(2)}ms`);

  // Batch size for config updates
  const BATCH_SIZE = 500;
  const configUpdates = new Map<string, any>();
  const processedParts: any[] = [];

  // Process parts in chunks to avoid blocking the main thread
  const parts = partDocs.docs;
  const totalParts = parts.length;
  const chunkProcessingStartTime = performance.now();
  let processedCount = 0;
  
  for (let i = 0; i < totalParts; i += BATCH_SIZE) {
    const chunkStartTime = performance.now();
    const chunk = parts.slice(i, Math.min(i + BATCH_SIZE, totalParts));
    
    chunk.forEach((doc) => {
      const partData = doc.data() as ICustomerPart;
      const _customer = customers.get(partData.customer) || partData.customer;

      try {
        // Get pricing data for this part
        const pricing = partPricing.get(partData.Sku);
        
        if (!partData.config) {
          // Even without config, we still want to include pricing
          processedParts.push({ 
            ...partData, 
            customer: _customer, 
            pricing: pricing || null 
          });
          return;
        }

        // Update config and handle parent relationships
        const updatedConfig = updateConfig(partData.config || [], configTerms);
        const Description = configToDescription(partData.config);

        // Queue update if needed
        if (!isEqual(partData.config, updatedConfig)) {
          configUpdates.set(partData.Sku, { config: updatedConfig });
        }

        // Process the part with its pricing
        const partCategory = includes(['GN', 'BN'], partData.type) ? 'neck' : 'body';
        const processedPart = {
          ...partData,
          config: updatedConfig,
          Description,
          customer: _customer,
          pricing: pricing || null
        };

        // If we have pricing data and pricingData (body/neck data), update the pricing
        if (pricing && pricingData) {
          const updatedPricing = updatePricing(
            { ...processedPart, partCategory }, 
            pricingData, 
            partCategory
          );
          processedPart.pricing = updatedPricing.pricing || null;
        }

        processedParts.push(processedPart);
      } catch (e) {
        console.error('Error processing part:', partData.Sku, e);
        processedParts.push({ ...partData, customer: _customer, pricing: null });
      }

      processedCount++;
      if (onProgress) {
        onProgress(((processedCount) / totalParts) * 50);
      }
    });

    console.log(`🕒 Processed chunk ${i/BATCH_SIZE + 1} (${chunk.length} items) in ${(performance.now() - chunkStartTime).toFixed(2)}ms`);
    // Allow other tasks to run between chunks
    await new Promise(resolve => setTimeout(resolve, 0));
  }

  console.log(`🕒 All chunks processed in ${(performance.now() - chunkProcessingStartTime).toFixed(2)}ms`);

  // Process config updates in batches
  const batchStartTime = performance.now();
  const updatePromises: Promise<void>[] = [];
  let currentBatch = writeBatch(db.firestore);
  let operationsInBatch = 0;

  for (const [sku, update] of configUpdates.entries()) {
    const docRef = doc(db.firestore, db.part.records, sku);
    currentBatch.update(docRef, update);
    operationsInBatch++;

    if (operationsInBatch === BATCH_SIZE) {
      updatePromises.push(currentBatch.commit());
      currentBatch = writeBatch(db.firestore);
      operationsInBatch = 0;
    }
  }

  if (operationsInBatch > 0) {
    updatePromises.push(currentBatch.commit());
  }

  // Fire all batch updates in parallel
  await Promise.all(updatePromises);
  console.log(`🕒 Batch updates completed in ${(performance.now() - batchStartTime).toFixed(2)}ms`);

  const pricingStartTime = performance.now();
  const result = processPricingData(processedParts, pricingData, childViewType, onProgress);
  console.log(`🕒 Price processing completed in ${(performance.now() - pricingStartTime).toFixed(2)}ms`);
  
  console.log(`🕒 Total fetchPricing time: ${(performance.now() - startTime).toFixed(2)}ms`);
  return result;
};

export const priceMultiplier = (value: number): string => {
  if (value < 0) return `${Math.floor((value * 100))}%`;
  if (value > 0) return `${Math.floor((value * 100))}%`;
  return '0%';
};

export const nextCustomerPart = (customer: string, customerParts: any[], partTypePattern: string) => {
  const partTypeMatcher = new RegExp(partTypePattern);
  const sameType = customerParts.filter((p: any) => p.type.match(partTypeMatcher));
  // const nextPart = customerParts
  //   .filter((p: any) => p.type.match(partTypeMatcher))
  const nextPart = sameType.map((p: any) => parseInt(p.Sku.split('_')[1], 10) + 1)
    .sort((a: number, b: number) => b - a);
  if (nextPart[0] >= 1000) return `${customer}_0${nextPart[0]}`;
  if (nextPart[0] >= 100) return `${customer}_00${nextPart[0]}`;
  if (nextPart[0] >= 10) return `${customer}_000${nextPart[0]}`;
  return `${customer}_0000${nextPart[0]}`;
  // }
  // return `${customer}_00000`;
};

export const isScannerStation = (userId: string) => {
  const stationIds = [
    'thoqTZixlrhlYxWy52GHUvNUUr42', // station-release
    'uuikiqzedJPiJ0t1zc9bJn2cDQt2', // station-assembly
    'n973nmDar7Xs7j74bmCJ8IcGlXp2', // station-construction
    CONSTRUCTION_JASON.stationId, // station-construction-jason
    'fUl6oWOQOYSHxDkI9BVYzgi8suj1', // station-dryroom
    '7l8NTrVLWFYReCmFYnbabqNXxTe2', // station-cnc
    'kF8mOZ8sIXXAOVPjFyJty8aFCfl1', // station-finishing
    'gEmUhPcMPHeCYUCA8o0dEXdBoSo1', // station-finishing-hand
    'mAmnJq0iJAUpSCZgKC5MIOEHrTD2', // station-qa-shipping
  ];

  return includes(stationIds, userId);
};

export const recordCsvData = (data: IShopOrder[]) => data.map((d: IShopOrder) => [
  d.customer.id,
  d.salesOrder,
  d.orderDate.toDate().toLocaleDateString(),
  d.type,
  d.partCount,
  d.description.replace(/,/g, ';'),
  `$${d.orderValue}`,
  d.releaseDate.toDate().toLocaleDateString(),
  d.runners?.length ? 'Released' : '',
  d.shipDate.toDate().toLocaleDateString(),
  d.completed ? 'Shipped' : '',
]);

export const PART_STATE_ATOM = {
  body: newBodyAtom,
  neck: newNeckAtom,
};

export const PART_DATA_ATOM = {
  body: bodyDataAtom,
  bodyTest: bodyDataAtom,
  neck: neckDataAtom,
  neckTest: neckDataAtom,
};

export const IS_MOBILE = /iPhone|iPad/i.test(navigator.userAgent);

export const RUNNER_BODY_INITIAL_STEP = 'ARft0FS3O';
export const RUNNER_NECK_INITIAL_STEP = 'bIKQ9ANcC';
export const ROUTER_JOB_TYPES = ['body', 'neck'];

export const BODY_HOLD_STEP = '_ic83Hl5F';
export const NECK_HOLD_STEP = 'JRgTgTROh';
export const HOLD_STEPS = ['On Hold', NECK_HOLD_STEP, BODY_HOLD_STEP];
export const READY_STEPS = [RUNNER_BODY_INITIAL_STEP, RUNNER_NECK_INITIAL_STEP, '-sWUWMT8b', 'ro2EFSo_X'];
export const NECK_READY_STEPS = [RUNNER_NECK_INITIAL_STEP, 'ro2EFSo_X'];
export const FINISHING_STEPS = ['Finishing', 'sOspM8A0Bhx', 'DsGT5gQg-dn', 'aaKxatWdehe', 'ejhdPLv2l', '99ehk9DSkpu'];
export const COMPLETE_STEPS = ['Complete', 'vTRZZbKL9', '73TxOo4eh'];
export const DRY_ROOM_STEP = '7kN0pSmXBw3';
export const BODY_WOOD_TYPES = [
  'SwAsh', 'Alder', 'Mahog', 'Pine', 'Rst. Pine', 'Other',
];

// takes an object array and reduces values in the array by key into a single object.
export const reduceObjectArrayValues = (objectArray: any[]) => objectArray.reduce((a: any, b: any) => {
  Object.keys(b).forEach((key) => {
    if (a[key] === undefined) {
      a[key] = b[key];
    } else {
      a[key] += b[key];
    }
  });
  return a;
}, {});
