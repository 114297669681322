import { resolveFirestorePath } from './helper';

const records = resolveFirestorePath('customers');
const contacts = resolveFirestorePath('customer-contacts');
const shippingAddresses = resolveFirestorePath('customer-shipping-addresses');
const terms = resolveFirestorePath('terms');

export default {
  records,
  contacts,
  shippingAddresses,
  terms,
};
